import React, { Suspense, useState, useEffect, useRef } from 'react';
import { BsFillChatFill } from 'react-icons/bs';
import ModalWindow from './ModalWindow';
import { Canvas } from '@react-three/fiber';
import { Experience } from '../Experience';
import annyang from 'annyang';
import { Html, useProgress } from '@react-three/drei'
import Config, { PYTHON_URL } from "../../Services/Config";
import { StopRounded } from '@mui/icons-material';
import SpeechModal from './ModalWindow/SpeechModal';
import NotificationComponent from './ModalWindow/NotificationComponent';

function ChatWidget(props) {
  const [hovered, setHovered] = useState(false);
  const [visible, setVisible] = useState(true);
  const [greet, setGreet] = useState(true);
  const [latestMsg, setLatestMsg] = useState('');
  const [recording, setRecording] = useState(false);
  const [recordedMessages, setRecordedMessages] = useState([]);
  const [showAvatar, setShowAvatar] = useState(true); // New state for avatar
  const widgetRef = useRef(null);
  const recognitionRef = useRef(null);
  const chatButtonRef = useRef(null);  // Ref for the "Chat Now" div
  const [speechEnabled, setSpeechEnabled] = useState(false);
    const handleEnableSpeech = () => {
        setSpeechEnabled(true); // Enable speech synthesis
      };
  const micStateRef = useRef({
    listening: false,
    timeout: null,
  });

  const handleLatestMsgUpdate = (msg) => {
    setLatestMsg(msg);
  };
  function Loader() {
    const { progress } = useProgress()
    return <Html center>{progress} % loaded</Html>
  }
  
  useEffect(() => {
    
    console.log("annyang",annyang);
    
    recognitionRef.current = annyang;

    if (recognitionRef.current) {
      recognitionRef.current.start();

      recognitionRef.current.addCallback('result', handleSpeechRecognitionResult);
    }
    

    return () => {
      if (recognitionRef.current) {
        recognitionRef.current.abort();
        recognitionRef.current.removeCallback('result', handleSpeechRecognitionResult);
      }
    };
    
  }, []);


  const handleOnNotify= (message) => {
    setLatestMsg(message); // Update the notification message when triggered
  };
  
  // useEffect(() => {
  //     if (annyang) {
  //         recognitionRef.current = annyang;

  //         // Add a callback for handling speech recognition results
  //         recognitionRef.current.addCallback('result', handleSpeechRecognitionResult);

  //         // Start listening
  //         recognitionRef.current.start();

  //         console.log("Speech recognition started.");

  //         return () => {
  //             // Abort recognition and remove the callback on component unmount
  //             recognitionRef.current.abort();
  //             recognitionRef.current.removeCallback('result', handleSpeechRecognitionResult);
  //             console.log("Speech recognition stopped.");
  //         };
  //     } else {
  //         console.error("annyang is not supported in this browser.");
  //     }
  // }, []);
//   useEffect(() => {
//     // Initialize recognitionRef here
//     recognitionRef.current = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
    
//     // Optionally set up recognitionRef.current properties
//     recognitionRef.current.continuous = true;
//     recognitionRef.current.interimResults = true;

//     // Add other event listeners here

//     return () => {
//         // Cleanup code if necessary
//         recognitionRef.current = null; // Clear it on unmount
//     };
// }, []);


  const handleSpeechRecognitionResult = async (phrases) => {
    
    const transcript = phrases[0];
    let messagetext = transcript.replace(/\./g, '');
    setLatestMsg(messagetext);

    if (micStateRef.current.timeout) {
      clearTimeout(micStateRef.current.timeout);
    }

    // micStateRef.current.timeout = setTimeout(() => {
    //   console.log("now stop stopListening inn 63");
    //   stopListening();
    // }, 2000);
    if (!messagetext) {
      console.log('Empty message, request not sent.');
      startListening();
      return; // Stop the function here if messagetext is blank
    }
    // try {      

    //   // const response = await fetch(`http://127.0.0.1:5000/send/${encodeURIComponent(messagetext)}`, {
      
    //   const response = await fetch(`${PYTHON_URL}send/${encodeURIComponent(messagetext)}`, {

    //     method: 'GET',
    //     headers: {
    //       'Content-Type': 'application/json'
    //     },
    //   });

    //   if (!response.ok) {
    //     console.log(`HTTP error! Status: ${response.status}`);
    //     throw new Error(`HTTP error! Status: ${response.status}`);
    //   }

    //   const responseData = await response.json();
    //   console.log('Response Data:', responseData);

    // } catch (error) {
    //   console.error('Error:', error);
    // }
  };

  const toggleChatWindow = () => {
    // setVisible(!visible);
    // setGreet(!greet);
    // if (!recording) {
    //   startListening();
    // } else {
    //   stopListening();
    // }
    // console.log("run toggleChatWindow");
    setVisible((prev) => !prev);
    setGreet((prev) => !prev);
    // if (recording) {
    //   stopListening();
    // } else {
    //   startListening();
    // }

    // if (!recording) {
    //   startListening();
    // } else {
    //   stopListening();
    // }
    
  };
  // useEffect(() => {
  //   setTimeout(() => {
  //     // setVisible(true);
  //     // setGreet(true);
  //     setRecording(false)
  //     setVisible(false)
  //     stopListening();
  //   }, 500);
    
  // }, []); 
  
  useEffect(() => {
    // Check the visibility state and start or stop listening accordingly
    if (visible) {
        console.log("now startListening");
        setLatestMsg("111111");

        startListening();
    } else {
        console.log("Chat window is not visible. Stopping listening.");
        stopListening();
    }
  }, [visible]); // This effect runs every time 'visible' changes

  const startListening = () => {
    console.log("visible",visible);
    
    
    console.log("now startListening");

    if (!micStateRef.current.listening) {
      recognitionRef.current.start();
      micStateRef.current.listening = true;
      setRecording(true);
    }
    // if (recognitionRef.current && !micStateRef.current.listening) { // Check if recognitionRef.current is not null
    //       recognitionRef.current.start();
    //       micStateRef.current.listening = true;
    //       setRecording(true);
      
    // } else {
    //     console.error("Recognition reference is null, cannot start listening.");
    // }
  };

  const stopListening = () => {
    console.log("now stopListening");
    console.log("recognitionRef.current is",recognitionRef.current);
    
    if (micStateRef.current.listening) {
      recognitionRef.current.abort();
      micStateRef.current.listening = false;
      setRecording(false);

      // setTimeout(() => {
      //   startListening();
      // }, 2000);
    }
  };

  const stopListening1 = () => {
    if (micStateRef.current.listening) {
      recognitionRef.current.abort();
      micStateRef.current.listening = false;
      setRecording(false);
    }
  };

  const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor) && !/Edg/.test(navigator.userAgent);

useEffect(() => {
  if (isChrome) {
    console.log("The browser is Chrome.");
    // You can show a message to the user or check if sound is allowed based on the `checkSoundPermission` function
  } else {
    console.log("The browser is not Chrome.");
  }
}, []);

  const calculateScale = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;

    const minScale = 0.5;
    const maxScale = 1.5;
    const idealWidth = 1200;
    const idealHeight = 800;

    const newScale = Math.max(
      minScale,
      Math.min(
        maxScale,
        Math.min(width / idealWidth, height / idealHeight)
      )
    );
    
    return newScale;
  };
    // Function to run stopListening three times with a delay, then startListening

    
    

  return (

    <div ref={widgetRef}>
      { showAvatar  && (
        // <div style={{ position: 'fixed', bottom: 0, right: 0, width: 400, height: 400, zIndex: 1000 }}>
        
        
        <div style={{ position: 'fixed', bottom: 0, right: 0, width: 400,  height: 450 }}>
          

          <Canvas
            shadows
            camera={{ position: [0, 10, 0], fov: 20 }}
            style={{ width: '100%', height: '100%'}}
          >
            <Suspense fallback={<Loader />}>
              <Experience
                
                greet={greet}
                latestMsg={latestMsg}
                scale={calculateScale()}
              />
            </Suspense>
            
          </Canvas>
          {/* Speech Modal beside Avatar */}
            
            {isChrome && !speechEnabled && (
              <NotificationComponent onNotify={handleOnNotify} /> 
             
            )}
        </div>
      )}

      <ModalWindow
        visible={visible}
        setLatestMsg={handleLatestMsgUpdate}
        latestMsg={latestMsg}
        setRecordedMessages={setRecordedMessages}
        startListening={startListening}
        stopListening={stopListening}
        stopListening1={stopListening1}
        recording={recording}
        onIdsUpdate={props.onIdsUpdate}
      />

      <div
        ref={chatButtonRef}

        onClick={() => {
          toggleChatWindow();
        }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        style={{
          border: hovered ? '1px solid black' : '',
          backgroundColor: '#bb0129',
          color: 'white',
          padding: '10px',
          borderRadius: '5px',
          position: 'fixed',
          bottom: 20,
          right: 20,
          cursor: 'pointer',
          zIndex: 1001, // Ensure the chat button is on top
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <BsFillChatFill size={20} color="white" />
          <span style={{ marginLeft: '5px' }}>Chat Now!!</span>
        </div>
      </div>

      {recordedMessages.length > 0 && (
        <div style={{ position: 'fixed', top: 20, right: 20, backgroundColor: '#fff', padding: '10px', borderRadius: '5px', zIndex: 1000 }}>
          <h2>Recorded Messages:</h2>
          <ul>
            {recordedMessages.map((msg, index) => (
              <li key={index}>{msg}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default ChatWidget;
