import React from "react";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RequestIcon from '@mui/icons-material/RequestPage';
import SponsorIcon from '@mui/icons-material/RequestPage'; 

import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import EditProperty from "../EditProperty";
import Config from "../../../Services/Config";
import { IMAGES_URL } from "../../../Services/Config";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ConstructionOutlined } from "@mui/icons-material";
import SponserProperty from "../SponserProperty";
import dayjs from 'dayjs';

const Properties = ({ properties , onClickRequest}) => {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isSponserDialogOpen, setIsSponserDialogOpen] = useState(false);
  const [isRequestDialogOpen, setRequestDialog] = useState(false);
  const [newVrUrl, setNewVrUrl] = useState('');
  const [new360, setNew360] = useState('');
  
  const [selectedPropertyIdRequest, setSelectedPropertyIdRequest] = useState(null);
  const [selectedPropertyId, setSelectedPropertyId] = useState(null);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('lg');
  const [maxDeleteWidth, setMaxDeleteWidth] = React.useState('sm')
  const [propertyDetails, setPropertyDetails] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [newName, setNewName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [newPrice, setNewPrice] = useState('');
  const [newShortDesc, setNewShortDesc] = useState('');
  const [newDescription, setNewDescription] = useState('');
  const [newCategory, setNewCategory] = useState('');
  const [newType, setNewType] = useState('');
  const [newMinDuration, setNewMinDuration] = useState('');
  const [newLocation, setNewLocation] = useState('');
  const [newLongitude, setNewLongitude] = useState('');
  const [newLatitude, setNewLatitude] = useState('');
  const [newOccupancyStatus, setNewOccupancyStatus] = useState('');
  const [newInstant, setNewInstant] = useState('');
  const [newNumBedroom, setNewNumBedroom] = useState('');
  const [newNumBeds, setNewNumBeds] = useState('');
  const [newNumGuests, setNewNumGuests] = useState('');
  const [newNumDedicatedBathrooms, setNewNumDedicatedBathrooms] = useState('');
  const [newNumSharedBathrooms, setNewNumSharedBathrooms] = useState('');
  const [newNumPrivateBathrooms, setNewNumPrivateBathrooms] = useState('');
  const [newStreet, setNewStreet] = useState('');
  const [newCity, setNewCity] = useState('');
  const [newState, setNewState] = useState('');
  const [newCountry, setNewCountry] = useState('');
  const [newZipCode, setNewZipCode] = useState('');
  const [newAmenities, setNewAmenities] = useState([]);
  const [amenitiesModified, setAmenitiesModified] = useState(false);
  const [selectedAmenities, setSelectedAmenities] = useState([]);
  const [newImages, setNewImages] = useState([]);
  const [openEditSnackbar, setOpenEditSnackbar] = useState(false);
  const [editSnackbarSeverity, setEditSnackbarSeverity] = useState("info");
  const [editSnackbarMessage, setEditSnackbarMessage] = useState('');
  const [newBuyRent, setNewBuyRent] = useState('');
  const [newAvailableAt, setNewAvailableAt] = useState('');
  const [newAvailabilityEndAt, setNewAvailabilityEndAt] = useState('');
  const isSaveDisabled = !startDate || !endDate;

  let x = 5;
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px', // Rounded edges
    transition: 'transform 0.3s ease-out', // Animation for the transform property
  };

  const handleNewNameChange = (newNameValue) => {
    setNewName(newNameValue);
  };

  const handleStartDateChange = (newValue) => {
    
    setStartDate(newValue);
  };
  const handleEndDateChange = (newValue) => {
    setEndDate(newValue);
  };

  const handleNewPriceChange = (newPriceValue) => {
    setNewPrice(newPriceValue);
  }

  const handleNewShortDescChange = (newShortDesc) => {
    setNewShortDesc(newShortDesc);
  }

  const handleNewDescriptionChange = (newDescriptionValue) => {
    setNewDescription(newDescriptionValue)
  }

  const handleNewCategoryChange = (newCategoryValue) => {
    setNewCategory(newCategoryValue)
  }

  const handleNewTypeChange = (newTypeValue) => {
    setNewType(newTypeValue)
  }

  const handleNewMinDurationChange = (newMinDurationValue) => {
    setNewMinDuration(newMinDurationValue)
  }

  const handleNewLocationChange = (newLocationValue) => {
    setNewLocation(newLocationValue)
  }

  const handleNewLongitudeChange = (newLongitudeValue) => {
    setNewLongitude(newLongitudeValue)
  }

  const handleNewLatitudeChange = (newLatitudeValue) => {
    setNewLatitude(newLatitudeValue);
  };

  const handleNewInstantChange = (newInstantValue) => {
    setNewInstant(newInstantValue);
  };

  const handleNewOccupancyChange = (newOccupancyValue) => {
    setNewOccupancyStatus(newOccupancyValue);
  };

  const handleNewNumBedroomChange = (newNumBedroomValue) => {
    setNewNumBedroom(newNumBedroomValue);
  };

  const handleNewNumBedsChange = (newNumBedsValue) => {
    setNewNumBeds(newNumBedsValue);
  };

  const handleNewNumGuestsChange = (newNumGuestsValue) => {
    setNewNumGuests(newNumGuestsValue);
  };

  const handleNewNumDedicatedBathroomsChange = (newNumDedicatedBathroomsValue) => {
    setNewNumDedicatedBathrooms(newNumDedicatedBathroomsValue);
  };

  const handleNewNumSharedBathroomsChange = (newNumSharedBathroomsValue) => {
    setNewNumSharedBathrooms(newNumSharedBathroomsValue);
  };

  const handleNewNumPrivateBathroomsChange = (newNumPrivateBathroomsValue) => {
    setNewNumPrivateBathrooms(newNumPrivateBathroomsValue);
  };

  const handleNewStreetChange = (newStreetValue) => {
    setNewStreet(newStreetValue);
  };

  const handleNewCityChange = (newCityValue) => {
    setNewCity(newCityValue);
  };

  const handleNewStateChange = (newStateValue) => {
    setNewState(newStateValue);
  };

  const handleNewCountryChange = (newCountryValue) => {
    setNewCountry(newCountryValue);
  };

  const handleNewZipCodeChange = (newZipCodeValue) => {
    setNewZipCode(newZipCodeValue);
  };
  const handleNewBuyRentChange = (newBuyRentValue) => {
    setNewBuyRent(newBuyRentValue);
  };

  const handleNewAvailableAtChange = (newAvailableAtValue) => {
    setNewAvailableAt(newAvailableAtValue);
  };

  const handleNewAvailabilityEndAtChange = (newAvailabilityEndAtValue) => {
    setNewAvailabilityEndAt(newAvailabilityEndAtValue);
  };
  const handleNewVrUrlChange = (newVrUrlValue) => {
    setNewVrUrl(newVrUrlValue);
  };
  
  const handleNew360Change = (new360Value) => {
    setNew360(new360Value);
  };
  
  const handleNewAmenitiesChange = (amenity, isChecked) => {

    setNewAmenities((prevAmenities) => {
      setAmenitiesModified(true);
      const isAmenitySelected = prevAmenities.some((a) => a.id === amenity.id);
      if (isChecked && !isAmenitySelected) {
        return [...prevAmenities, amenity];
      } else if (!isChecked && isAmenitySelected) {
        return prevAmenities.filter((a) => a.id !== amenity.id);
      }
      return prevAmenities;
    });
  };


  

  useEffect(() => {
    const fetchPropertyDetails = async () => {
      if (selectedPropertyId) {
        try {
          const propertyData = await Config.getPropertyDetails(selectedPropertyId);
          // console.log("result property data from api : ",propertyData);
          setPropertyDetails(propertyData);
        } catch (error) {
          console.error("Error fetching property details:", error);
        }
      } else {
        // console.log("selected property : ",selectedPropertyId);
      }
    };

    fetchPropertyDetails();
  }, [selectedPropertyId]);




  const deletePropertyFunction = async (property_id) => {
    try {
      const result = await Config.deleteProperty(property_id);
      console.log('API Response:', result);

      if (result && result.status === 'Success' && result.msg) {
        console.log('Setting Snackbar...');
        setSnackbarMessage(result.msg);
        setIsSnackbarOpen(true);

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        console.log('Unexpected API response:', result);
      }

      // setSelectedPropertyId(null);
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const handleSponserProperty = async () => {
    try {

      const formDataa = new FormData();
      formDataa.append('propertyId', selectedPropertyId);
      formDataa.append('startDate', dayjs(startDate).format('YYYY-MM-DD HH:mm:ss'));
      formDataa.append('endDate', dayjs(endDate).format('YYYY-MM-DD ') + "23:59:59");

      const result = await Config.sponsorProperty(formDataa);
  
      if (result.success) {
        console.log(result.message);
        setOpenEditSnackbar(true);
        setEditSnackbarMessage(result.message);
        setEditSnackbarSeverity("success");
        // setSelectedPropertyId(null);
        setTimeout(() => {
          window.location.reload();
        }, 4000);
      } else {
        console.log('result', result);
        setOpenEditSnackbar(true);
        setEditSnackbarMessage(result.message);
        setEditSnackbarSeverity("error");
      }
  
      // setSelectedPropertyId(null);
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const handleEditProperty = async () => {
    try {
      const uniqueAmenitiesIds = [...new Set(propertyDetails.amenities.map((amenity) => amenity.id))];
  
      const priceAsNumber = newPrice !== null && newPrice !== '' ? parseFloat(newPrice) : propertyDetails.price;
      const categoryAsNumber = newCategory !== null && newCategory !== '' ? parseFloat(newCategory) : propertyDetails.category;
      const typeAsNumber = newType !== null && newType !== '' ? parseFloat(newType) : propertyDetails.type;
      const minDurationAsNumber = newMinDuration !== null && newMinDuration !== '' ? parseFloat(newMinDuration) : propertyDetails.min_duration;
      const longitudeAsNumber = newLongitude !== null && newLongitude !== '' ? parseFloat(newLongitude) : propertyDetails.longitude;
      const latitudeAsNumber = newLatitude !== null && newLatitude !== '' ? parseFloat(newLatitude) : propertyDetails.latitude;
      const instantAsNumber = newInstant !== null && newInstant !== '' ? (newInstant ? 1 : 0) : propertyDetails.instant_booking;
      const occupancyAsNumber = newOccupancyStatus !== null && newOccupancyStatus !== '' ? (newOccupancyStatus ? 1 : null) : propertyDetails.occupancy_status_id;
      const numBedroomAsNumber = newNumBedroom !== null && newNumBedroom !== '' ? parseFloat(newNumBedroom) : propertyDetails.num_bedrooms;
      const numBedsAsNumber = newNumBeds !== null && newNumBeds !== '' ? parseFloat(newNumBeds) : propertyDetails.num_beds;
      const numGuestsAsNumber = newNumGuests !== null && newNumGuests !== '' ? parseFloat(newNumGuests) : propertyDetails.num_guests;
      const numDedicatedBathroomsAsNumber = newNumDedicatedBathrooms !== null && newNumDedicatedBathrooms !== '' ? parseFloat(newNumDedicatedBathrooms) : propertyDetails.dedicated_bathroom;
      const numSharedBathroomsAsNumber = newNumSharedBathrooms !== null && newNumSharedBathrooms !== '' ? parseFloat(newNumSharedBathrooms) : propertyDetails.shared_bathroom;
      const numPrivateBathroomsAsNumber = newNumPrivateBathrooms !== null && newNumPrivateBathrooms !== '' ? parseFloat(newNumPrivateBathrooms) : propertyDetails.private_bathroom;
      const zipcodeAsNumber = newZipCode !== null && newZipCode !== '' ? parseFloat(newZipCode) : propertyDetails.zip_code;
  
      const editedPropertyDetails = {
        ...propertyDetails,
        amenities: selectedAmenities.map((amenity) => amenity.id).join(','),
        name: newName !== null && newName !== '' ? newName : propertyDetails.name,
        price: !isNaN(priceAsNumber) ? priceAsNumber : propertyDetails.price,
        short_desc: newShortDesc !== null && newShortDesc !== '' ? newShortDesc : propertyDetails.short_desc,
        description: newDescription !== null && newDescription !== '' ? newDescription : propertyDetails.description,
        category_id: !isNaN(categoryAsNumber) ? categoryAsNumber : propertyDetails.category_id,
        type_id: !isNaN(typeAsNumber) ? typeAsNumber : propertyDetails.type_id,
        min_duration: !isNaN(minDurationAsNumber) ? minDurationAsNumber : propertyDetails.min_duration,
        location: newLocation !== null && newLocation !== '' ? newLocation : propertyDetails.location,
        longitude: !isNaN(longitudeAsNumber) ? longitudeAsNumber : propertyDetails.longitude,
        latitude: !isNaN(latitudeAsNumber) ? latitudeAsNumber : propertyDetails.latitude,
        instant_booking: instantAsNumber,
        occupancy_status_id: occupancyAsNumber,
        num_bedrooms: !isNaN(numBedroomAsNumber) ? numBedroomAsNumber : propertyDetails.num_bedrooms,
        num_beds: !isNaN(numBedsAsNumber) ? numBedsAsNumber : propertyDetails.num_beds,
        num_guests: !isNaN(numGuestsAsNumber) ? numGuestsAsNumber : propertyDetails.num_guests,
        dedicated_bathroom: !isNaN(numDedicatedBathroomsAsNumber) ? numDedicatedBathroomsAsNumber : propertyDetails.dedicated_bathroom,
        shared_bathroom: !isNaN(numSharedBathroomsAsNumber) ? numSharedBathroomsAsNumber : propertyDetails.shared_bathroom,
        private_bathroom: !isNaN(numPrivateBathroomsAsNumber) ? numPrivateBathroomsAsNumber : propertyDetails.private_bathroom,
        street: newStreet !== null && newStreet !== '' ? newStreet : propertyDetails.street,
        city: newCity !== null && newCity !== '' ? newCity : propertyDetails.city,
        state: newState !== null && newState !== '' ? newState : propertyDetails.state,
        country: newCountry !== null && newCountry !== '' ? newCountry : propertyDetails.country,
        zip_code: !isNaN(zipcodeAsNumber) ? zipcodeAsNumber : propertyDetails.zip_code,
        property_images: newImages.length > 0 ? newImages.map((image) => image) : propertyDetails.property_images.map((image) => image.image_url),
        rent_buy: newBuyRent !== '' ? (newBuyRent === '0' ? 0 : 1) : propertyDetails.rent_buy,
        available_at: newAvailableAt !== '' ? newAvailableAt : propertyDetails.available_at,
        availability_end_at: newAvailabilityEndAt !== '' ? newAvailabilityEndAt : propertyDetails.availability_end_at,
        vr_url: newVrUrl !== '' ? newVrUrl : propertyDetails.vr_url,
        image360_url: new360 !== '' ? new360 : propertyDetails.image360_url,
      };
  
      if (!amenitiesModified) {
        editedPropertyDetails.amenities = uniqueAmenitiesIds.join(',');
      } else {
        const uniqueAmenities = new Set(selectedAmenities.map((amenity) => amenity.id));
        editedPropertyDetails.amenities = [...uniqueAmenities].join(',');
      }
  
      console.log("my request", editedPropertyDetails);
  
      const result = await Config.editProperty(editedPropertyDetails);
  
      if (result.success) {
        console.log(result.message);
        setOpenEditSnackbar(true);
        setEditSnackbarMessage(result.message);
        setEditSnackbarSeverity("success");
        setTimeout(() => {
          window.location.reload();
        }, 4000);
      } else {
        console.log('result', result);
      }
  
      // setSelectedPropertyId(null);
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  const handleRowClick = (propertyId) => {
  //   const url = `/propertydetails?id=${propertyId}`;
  //    window.open(url, "_blank");
   };

  if (!properties) {
    console.log("jdfnjn")
    return null;
  }

  const handleEditClick = (propertyId) => {
    console.log("property id",propertyId)
    setSelectedPropertyId(propertyId);
    
    setIsEditDialogOpen(true);
  };
  
  const handleSponcerClick = (propertyId) => {
    // console.log("property id",propertyId)
    setSelectedPropertyId(propertyId);
    setIsSponserDialogOpen(true);
  };

  const handleRequestClick = (propertyId) => {
    setSelectedPropertyIdRequest(propertyId);
    // console.log("property Id", propertyId);
  };


  const handleCloseEditDialog = () => {
    setIsEditDialogOpen(false);
    setSelectedPropertyId(null);
  };
  const handleCloseSponsorDialog = () => {
    setIsSponserDialogOpen(false);
    setSelectedPropertyId(null);
  };

  const handleOpenDeleteDialog = (propertyId) => {
    setSelectedPropertyId(propertyId);
    setDeleteDialog(true);
  }

  const handleCloseDeleteDialog = () => {
    setDeleteDialog(false);
  }

  const handleSnackbarClose = () => {
    setIsSnackbarOpen(false);
  };

  const handleEditSnackbarClose = () => {
    setOpenEditSnackbar(false);
  };

  const cellStyle = {
    padding: '8px',
    textAlign: 'left',
  };

  const buttonStyle = {
    minWidth: '30px', // Smaller button size
    padding: '4px 8px', // Reduced padding for smaller buttons
    marginRight: '8px', // Space between buttons
  };

  return (
    <div>
         <TableContainer component={Paper}>
      <Table aria-label="property table">
        <TableHead>
          <TableRow>
            <TableCell style={cellStyle}>Image</TableCell>
            <TableCell style={cellStyle}>Name</TableCell>
            <TableCell style={cellStyle}>Location</TableCell>
            <TableCell style={cellStyle}>Price</TableCell>
            <TableCell style={cellStyle} colSpan={1}>rent_buy</TableCell>
            <TableCell style={cellStyle} colSpan={4}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {properties.length > 0 ? (
            properties.map((property) => (
              <TableRow key={property.id} hover onClick={(event) => handleRowClick(property.id, event)} style={{ cursor: 'pointer' }}>
                <TableCell style={{ ...cellStyle, width: '120px' }}>
                  <img src={IMAGES_URL + (property.property_images?.[0]?.image_url || '')} alt={property.name} style={{ width: '100px', height: '75px', objectFit: 'cover' }} />
                </TableCell>
                <TableCell style={{ ...cellStyle, width: '200px' }}>{property.name}</TableCell>
                <TableCell style={{ ...cellStyle, width: '150px' }}>{property.location}</TableCell>
                <TableCell style={{ ...cellStyle, width: '120px' }}>${property.price}</TableCell>
                <TableCell style={{ ...cellStyle, width: '400px' }} colSpan={1}>
  {property.rent_buy === 0 ? 'rent/Night' : 'For sale'}
</TableCell>

                <TableCell style={{ ...cellStyle, textAlign: 'left', width: '200px' }} colSpan={4} >
                  <Button
                    variant="outlined"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEditClick(property.id);
                    }}
                    onClose={handleCloseEditDialog}
                    style={buttonStyle}
                  >
                    <EditIcon />
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSponcerClick(property.id);
                    }}
                    onClose={handleCloseSponsorDialog}
                    style={buttonStyle}
                  >
                    <SponsorIcon />
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRequestClick(property.id);
                      onClickRequest(property.id);
                    }}
                    style={buttonStyle}
                  >
                    <RequestIcon />
                  </Button>
                  <Button
                    variant="outlined"
                    style={{ ...buttonStyle, color: "#bb0129", borderColor: "#bb0129" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleOpenDeleteDialog(property.id);
                    }}
                  >
                    <DeleteIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell style={{ textAlign: 'center' }} colSpan={7}>No Properties with this Name</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>


    {/* Dilog for sponser  */}
    <Dialog
        open={isSponserDialogOpen}
        onClose={handleCloseSponsorDialog}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        sx={{ minWidth: "400px" }}
      >
        <DialogTitle>Sponser Property</DialogTitle>

        <DialogContent dividers>
          <SponserProperty
                        propertyDetails={propertyDetails}
                        handleCloseSponsorDialog={handleCloseSponsorDialog}
                        handleStartDateChange={handleStartDateChange}
                        handleEndDateChange={handleEndDateChange}
                        selectedPropertyId={selectedPropertyId}
                    />

        </DialogContent>

        <DialogActions>
          <Button onClick={handleSponserProperty} disabled={isSaveDisabled} color="primary" variant="outlined">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isEditDialogOpen}
        onClose={handleCloseEditDialog}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        sx={{ minWidth: "400px" }}
      >
        <DialogTitle>Edit Property</DialogTitle>

        <DialogContent dividers>
        <EditProperty
            propertyDetails={propertyDetails}
            handleCloseEditDialog={handleCloseEditDialog}
            handleNewNameChange={handleNewNameChange}
            handleNewPriceChange={handleNewPriceChange}
            handleNewShortDescChange={handleNewShortDescChange}
            handleNewDescriptionChange={handleNewDescriptionChange}
            handleNewCategoryChange={handleNewCategoryChange}
            handleNewTypeChange={handleNewTypeChange}
            handleNewMinDurationChange={handleNewMinDurationChange}
            handleNewLocationChange={handleNewLocationChange}
            handleNewLongitudeChange={handleNewLongitudeChange}
            handleNewLatitudeChange={handleNewLatitudeChange}
            handleNewInstantChange={handleNewInstantChange}
            handleNewOccupancyChange={handleNewOccupancyChange}
            handleNewNumBedroomChange={handleNewNumBedroomChange}
            handleNewNumBedsChange={handleNewNumBedsChange}
            handleNewNumGuestsChange={handleNewNumGuestsChange}
            handleNewNumDedicatedBathroomsChange={handleNewNumDedicatedBathroomsChange}
            handleNewNumSharedBathroomsChange={handleNewNumSharedBathroomsChange}
            handleNewNumPrivateBathroomsChange={handleNewNumPrivateBathroomsChange}
            handleNewStreetChange={handleNewStreetChange}
            handleNewCityChange={handleNewCityChange}
            handleNewStateChange={handleNewStateChange}
            handleNewCountryChange={handleNewCountryChange}
            handleNewZipCodeChange={handleNewZipCodeChange}
            handleNewAmenitiesChange={handleNewAmenitiesChange}
            newAmenities={newAmenities}
            selectedAmenities={selectedAmenities}
            setSelectedAmenities={setSelectedAmenities}
            newImages={newImages}
            setNewImages={setNewImages}
            handleNewBuyRentChange={handleNewBuyRentChange}
            handleNewAvailableAtChange={handleNewAvailableAtChange}
            handleNewAvailabilityEndAtChange={handleNewAvailabilityEndAtChange}
            newBuyRent={newBuyRent}
            newAvailableAt={newAvailableAt}
            newAvailabilityEndAt={newAvailabilityEndAt}
            newVR={newVrUrl} // Add VR URL
            setNewVR={setNewVrUrl} // Function to set VR URL
            new360={new360} // Add 360 video URL
            setNew360={setNew360} // Function to set 360 video URL
        />

        </DialogContent>

        <DialogActions>
          <Button onClick={handleEditProperty} color="primary" variant="outlined">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

      <Modal
        open={deleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Confirmation
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Are you sure you want to remove this property?
            </Typography>
            <div style={{ marginLeft: 'auto', marginTop: '20px' }}>
              <Button variant="text" sx={{ color: 'grey', marginRight: '5px' }} onClick={handleCloseDeleteDialog}>Cancel</Button>
              <Button variant="text" color="primary" onClick={() => deletePropertyFunction(selectedPropertyId)}>Yes, Remove</Button>
            </div>
          </div>
        </Box>
      </Modal>

      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={10000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity="success"
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>

      <Snackbar
        open={openEditSnackbar}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleEditSnackbarClose}
          severity={editSnackbarSeverity}
        >
          {editSnackbarMessage}
        </MuiAlert>
      </Snackbar>

    </div>
  );
};

export default Properties;