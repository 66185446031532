import React, { useState, useEffect } from 'react';

const SoundPermission = () => {
  const [hasPermission, setHasPermission] = useState(null);

  const requestMicrophoneAccess = async () => {
    try {
      // Request access to microphone
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setHasPermission(true);
      // Do something with the microphone stream (e.g., show audio visualization, etc.)
      console.log('Microphone access granted', stream);
    } catch (error) {
      setHasPermission(false);
      console.error('Microphone access denied', error);
    }
  };

  return (
    <div>
      <button onClick={requestMicrophoneAccess}>
        Request Microphone Access
      </button>
      {hasPermission === true && <p>Microphone Access Granted</p>}
      {hasPermission === false && <p>Microphone Access Denied</p>}
    </div>
  );
};

export default SoundPermission;
