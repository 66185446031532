import React, { useState, useEffect } from 'react';
import Slider from 'rc-slider';
import './RadiusRange.css';
import 'rc-slider/assets/index.css';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

const RadiusRangeInput = ({ min: initialMin, max: initialMax, onRangeChange }) => {
  const [minRange, setMinRange] = useState(initialMin);
  const [maxRange, setMaxRange] = useState(initialMax);

  useEffect(() => {
    onRangeChange(minRange, maxRange);
  }, [minRange, maxRange, onRangeChange]);

  const handleSliderChange = (values) => {
    values[0] = 0;
    if (values[1] == values[0]) {
      values[1]++;
    }
    setMinRange(values[0]);
    setMaxRange(values[1]);
  };

  const sliderStyle = {
    width: '80%',
    margin: '0 auto',
  };

  const handleMinRangeChange = (e) => {
    if(e.target.value < 0){
        e.target.value = 0;
    }

    if(e.target.value === ""){
      setMinRange(0);
    }


    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value >= 0 && value <= 200) {
      setMinRange(value);
    }
  };

  const handleMaxRangeChange = (e) => {

    if(e.target.value < 0){
      e.target.value = 0;
    }

    if(e.target.value === ""){
      setMaxRange('');
    }

    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value >= 0 && value <= 200) {
      setMaxRange(value);
    }
  };

  return (
    <div className="radiusrange">
      <h4 className="label-range">Distance Range:</h4>

      <div className="inputs" style={{ marginTop: '15px' }}>
       
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div className="input1">
            <FormControl sx={{ width: '80%' }}>
              <InputLabel
                htmlFor="outlined-adornment-distance"
                sx={{
                  color: 'black',
                  "&.Mui-focused": {
                    color: '#bb0129',
                  },
                }}
              >
                Minimum
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-distance"
                startAdornment={<InputAdornment position="start">KM</InputAdornment>}
                label="Minimum"
                type="string"
                value={minRange.toString()}
                onChange={handleMinRangeChange}
                sx={{
                  height: '2.83rem',
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: '#bb0129',
                    "& .MuiInputLabel-root": {
                      color: '#bb0129',
                      "&.Mui-focused": {
                        color: '#bb0129',
                      },
                    },
                  },
                }}
              />
            </FormControl>
          </div>
          <div className="input1">
            <FormControl sx={{ width: '80%' }}>
              <InputLabel
                htmlFor="outlined-max-distance"
                sx={{
                  color: 'black',
                  "&.Mui-focused": {
                    color: '#bb0129',
                  },
                }}
              >
                Maximum
              </InputLabel>
              <OutlinedInput
                id="outlined-max-distance"
                startAdornment={<InputAdornment position="start">KM</InputAdornment>}
                label="Maximum"
                type="string"
                value={maxRange >= 200 ? '200+' : maxRange.toString()}
                onChange={handleMaxRangeChange}
                sx={{
                  height: '2.83rem',
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: '#bb0129',
                    "& .MuiInputLabel-root": {
                      color: '#bb0129',
                      "&.Mui-focused": {
                        color: '#bb0129',
                      },
                    },
                  },
                }}
              />
            </FormControl>
          </div>
        </Box>
      </div>

      <div className="slider-container">
        <Slider
          min={0}
          max={200}
          step={1}
          range
          defaultValue={[minRange, maxRange]}
          onChange={handleSliderChange}
          style={sliderStyle}
          className="range-slider"
        />
      </div>
    </div>
  );
};

export default RadiusRangeInput;
