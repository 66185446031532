import React, { useRef, useState, useEffect } from 'react';
import { BsFillHandIndexThumbFill   } from 'react-icons/bs';

const SpeechModal = ({ onEnableSpeech }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [topPosition, setTopPosition] = useState('50px'); // Default value
  const myRef = useRef(null);


  useEffect(() => {
    const updateTopPosition = () => {
      if (myRef.current) {
        // Add a slight delay to ensure layout is complete before measuring
        setTimeout(() => {
          const height = myRef.current.offsetHeight;
          setTopPosition(`${height + 20}px`);
        }, 0);
      }
    };

    // Run on mount and whenever `isMobile` changes
    updateTopPosition();

    // Optional: Add an event listener for window resize
    window.addEventListener('resize', updateTopPosition);
    return () => window.removeEventListener('resize', updateTopPosition);
  }, [isMobile]); // Recalculate if `isMobile` changes
  useEffect(() => {
    // Check window size and update isMobile state
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    // Run resize handler initially and on window resize
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    // if mobile: style hode else style modalStyles
    

    <div ref={myRef} style={isMobile ? {display: 'flex',
                                        justifyContent: 'center',
                                        // alignItems: 'center',
                                        position: 'fixed',
                                        top: topPosition,
                                        left: 0,
                                        width: '100%',
                                        height: 'auto',
                                        // backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                        zIndex: 1000,
                                      }
                                      
                                      :  modalStyles}> 
      <div style={modalContentStyles}>
        
        <p>Please click here to speak with me:</p>
       
        
        <button 
        style={{
          border: '1px solid black',
          backgroundColor: '#bb0129',
          color: 'white',
          padding: '10px',
          borderRadius: '5px',
          cursor: 'pointer',
          zIndex: 1001, // Ensure the chat button is on top
        }}
        onClick={onEnableSpeech}>
          <BsFillHandIndexThumbFill size={18} color="white" /> Click here
        </button>
      </div>
    </div>
  );
  // return (
  //   <div style={modalStyles}>
  //     <div style={modalContentStyles}>
  //       <p>Please click here to speak with me:</p>
        
  //       <button 
  //       style={{
  //         border: '1px solid black' ,
  //         backgroundColor: '#bb0129',
  //         color: 'white',
  //         padding: '10px',
  //         borderRadius: '5px',
          
  //         cursor: 'pointer',
  //         zIndex: 1001, // Ensure the chat button is on top
  //       }}
  //       onClick={onEnableSpeech}><BsFillHandIndexThumbFill   size={18} color="white" />  Click here</button>
  //     </div>
  //   </div>
  // );
};

// Styles for the modal (You can also use CSS-in-JS libraries like styled-components or inline styles)
// const modalStyles = {

//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center',
//   position: 'fixed', // Make the modal appear beside the avatar
//   bottom: "17px",
//   left: "100%", // Position the modal to the right of the avatar
//   marginLeft: '-470px', // Space between the avatar and the modal
//   backgroundColor: 'rgba(0, 0, 0, 0.5)',
//   width: '200px', // Adjust the width of the modal as necessary
//   height: 'auto', // Adjust the height if needed
//   padding: '10px',
//   borderRadius: '8px',
  
//   // Make the modal responsive for smaller screens
//   '@media (max-width: 768px)': {
//     left: '50%',  // Center the modal on mobile
//     marginLeft: '-120px', // Adjust to fit smaller screens
//     width: '80vw',  // Use relative width on mobile
//     height: 'auto',
//   },

//   '@media (max-width: 480px)': {
//     width: '90vw',  // Further reduce width for very small screens
//     marginLeft: '-120px',
//   },


// };

// const modalContentStyles = {
//   backgroundColor: 'white',
//   padding: '20px',
//   borderRadius: '8px',
//   textAlign: 'center',
//   width: '80%',
//   maxWidth: '300px',

//   // Adjust the content padding and size for smaller screens
//   '@media (max-width: 768px)': {
//     width: '90%', // Make it wider on mobile
//     padding: '15px', // Reduce padding on smaller screens
//   },
//   '@media (max-width: 480px)': {
//     width: '90%',
//     padding: '10px', // Further reduce padding for very small screens
//   },
// };


// Default styles for larger screens
const modalStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  bottom: '17px',
  left: '100%',
  marginLeft: '-470px',
  // backgroundColor: 'rgba(0, 0, 0, 0.5)',
  width: '200px',
  height: 'auto',
  padding: '10px',
  borderRadius: '8px',
};
// Default styles for larger screens
const modalStyles1 = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 1000,
}

// Responsive styles for mobile screens
const mobileModalStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  bottom: '17px',
  left: '50%', // Center the modal on mobile
  marginLeft: '-120px', // Adjust to fit smaller screens
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  width: '80vw', // Use relative width on mobile
  height: 'auto',
  padding: '10px',
  borderRadius: '8px',
};
// Responsive styles for mobile screens
// const mobileModalStyles1 = {
//   display: 'flex',
//   justifyContent: 'center',
//   // alignItems: 'center',
//   position: 'fixed',
//   top: topPosition,
//   left: 0,
//   width: '100%',
//   height: 'auto',
//   backgroundColor: 'rgba(0, 0, 0, 0.5)',
//   zIndex: 1000,
// };
// Responsive styles for mobile screens
const modalContentStyles = {
  backgroundColor: 'white',
  padding: '20px',
  borderRadius: '8px',
  textAlign: 'center',
  width: '80%',
  maxWidth: '300px',
};


export default SpeechModal;
