import React, { useState } from 'react';
import './Signup2.css'; // Import your stylesheet
import { Modal, Box } from '@mui/material';
import AuthService from '../Services/AuthService';
import SnackbarComponent from "../SnackBar/SnackBar";
import { Button, TextField, Snackbar } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import AppleIcon from '@mui/icons-material/Apple';
import EmailIcon from '@mui/icons-material/Email';
const Signup = ({ closeSignupAndOverlay, openLoginForm }) => {

  // console.log("sign up")

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");

  // const handleSignup = (e) => {
  //   e.preventDefault();
  //   // Implement your signup logic here
  //   console.log('Signup button clicked!');
  //   console.log('Username:', username);
  //   console.log('Email:', email);
  //   console.log('Password:', password);

  const hanldePhoneNumber = (event) => {
    let inputValue = event.target.value;

    if (inputValue === "") {
      setPhoneNumber("");
    } else {
      const numericValue = inputValue.replace(/[^0-9]/g, "");
      // const parsedValue = parseInt(inputValue, 10);
      if (!isNaN(parseInt(numericValue)) && numericValue.length > 0) {
        
        setPhoneNumber(inputValue);
      }
    }
  };

  useState(() => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhoneNumber('');
    setPassword('');
  });



  //   // Add your signup API call or other logic here
  // };


  const handleSignup = async (e) => {
    e.preventDefault();
    try {
      const body = {
        name : firstName,
        last_name : lastName,
        phone_number: phoneNumber,
        email: email,
        password: password,
      };
      // console.log("body",body);
      AuthService.signup(body).then(
        (response) => {
          // console.log(response);
          let signUpResponseMessage, severity;
          // console.log(response);
          if (response.success) {
            signUpResponseMessage = "Account Created Successfully";
            severity = "success";
            setTimeout(() => {
              window.location.reload(false);
          }, 500);
          } else {
            signUpResponseMessage = response.message;
            severity = "error";
          }

          setOpenSnackbar(true);
          setSnackbarMessage(signUpResponseMessage);
          setSnackbarSeverity(severity);

          // console.log(signUpResponseMessage);
        },
        (error) => {
          console.log("Login failed", error);
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleSocialLogin = (platform) => {
    console.log(`${platform} login clicked`);
    
    // Handle social login (Google, Facebook, etc.)
  };

 

  return (
    <div className="signup-form-container fontfamily">
      <div className="header-container">
        <h5>Sign Up</h5>
        <div className="close-btn">
          <button className="close-button" onClick={closeSignupAndOverlay}>
            x
          </button>
        </div>
      </div>
  
      <div className="form-scroll-container">
        <form onSubmit={handleSignup} className="signup-form1">
          <TextField
            label="First Name"
            variant="outlined"
            fullWidth
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            style={{ marginBottom: '16px' }}
          />
          <TextField
            label="Last Name"
            variant="outlined"
            fullWidth
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            style={{ marginBottom: '16px' }}
          />
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{ marginBottom: '16px' }}
          />
          <TextField
            label="Phone Number"
            variant="outlined"
            fullWidth
            value={phoneNumber}
            onChange={hanldePhoneNumber}
            style={{ marginBottom: '16px' }}
          />
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{ marginBottom: '16px' }}
          />
  
          <Button
            className=" signup-btn fontfamily"
            type="submit"
            variant="contained"
            fullWidth
            style={{
              marginBottom: '16px',
             
            }}
          >
            Sign Up
          </Button>
        </form>
  
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
          <label style={{ marginRight: '5px', fontSize: '14px', fontWeight: '500' }}>
            Already have an account?{" "}
          </label>
          <a href onClick={openLoginForm} style={{ cursor: 'pointer', color: '#0077FF' }}>
            Log in
          </a>
        </div>
  
        {/* <div className="separator">
          <span className="line"></span>
          <span className="or">or</span>
          <span className="line"></span>
        </div>
  
        <div className="social-login-buttons">
          <Button
            variant="outlined"
            color="primary"
            startIcon={<GoogleIcon />}
            fullWidth
            onClick={() => handleSocialLogin('Google')}
            style={{ marginBottom: '8px', borderRadius: '8px' }}
          >
            Continue with Google
          </Button>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<FacebookIcon />}
            fullWidth
            onClick={() => handleSocialLogin('Facebook')}
            style={{ marginBottom: '8px', borderRadius: '8px' }}
          >
            Continue with Facebook
          </Button>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<AppleIcon />}
            fullWidth
            onClick={() => handleSocialLogin('Apple')}
            style={{ marginBottom: '8px', borderRadius: '8px' }}
          >
            Continue with Apple
          </Button>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<EmailIcon />}
            fullWidth
            onClick={() => handleSocialLogin('Email')}
            style={{ borderRadius: '8px' }}
          >
            Continue with Email
          </Button>
        </div> */}
      </div>
  
      <Snackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        message={snackbarMessage}
        autoHideDuration={3000}
      />
    </div>
  );
  
};

export default Signup;
