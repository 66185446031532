// Filters.js

import RoomButtons from './RoomButtons/RoomButtons';
import PropertyType from './PropertyType/PropertyType';
import { useState, useEffect } from 'react';
import PriceRangeInput from './PriceRange/PriceRangeInput';
import RadiusRangeInput from './RadiusRange/RadiusRange';
import Divider from '@mui/material/Divider';
import { Button, Modal, Typography } from 'antd';
import { ConstructionOutlined } from '@mui/icons-material';
import { Box } from 'lucide-react';
import "./Filters.css";

let currentSelectedPropertyType = null;
let localMinPrice = 0;
let localMaxPrice = 1000;
let localMinRange = 0;
let localMaxRange = 200;
let currentSelectedRooms = { bedroom: '0', beds: '0', bathroom: '0' };

const Filters = ({ isOpen, 
  onApplyFilters, 
  onRangeChange, 
  selectedPropertyType, 
  setSelectedPropertyType, 
  selectedBedroom, 
  setSelectedBedroom, 
  selectedBeds, 
  setSelectedBeds, 
  selectedBathroom, 
  setSelectedBathroom}) => {

  const [selectedRooms, setSelectedRooms] = useState(() => currentSelectedRooms);

  const [filterDialog, setFilterDialog] = useState(false);

 

  const handleOpenFilterDialog = () => {
    // setFilterDialog(true);

    console.log("property Type",currentSelectedPropertyType);
    console.log("min Price",localMinPrice);
    console.log("max Price",localMaxPrice);
    console.log("min range",localMinRange);
    console.log("max range",localMaxRange);
    console.log("bedrooms",selectedBedroom);
    console.log("beds",selectedBeds);
    console.log("bathroom",selectedBathroom);

    if(currentSelectedPropertyType !== 0){
      setSelectedPropertyType(0);
    }

    if(localMinPrice !== 0){
      localMinPrice = 0;
    }

    if(localMaxPrice !== 1000){
      localMaxPrice = 1000;
    }

    if(localMinRange !== 0){
      localMinRange = 0;
    }

    if(localMaxRange !== 200){
      localMaxRange = 200;
    }

    if(selectedBedroom !== ""){
      selectedBedroom = "";
      setSelectedBedroom("");
    }

    if(selectedBeds !== ""){
      selectedBeds = "";
      setSelectedBeds("");
    }

    if(selectedBathroom !== ""){
      selectedBathroom = "";
      setSelectedBathroom("");
    }

    window.location.reload();

  }

  // const handleCloseFilterDialog = () => {
  //   setFilterDialog(false);
  // }


  useEffect(() => {
    // Save the selected property type to local storage when it changes
    localStorage.setItem('selectedPropertyType', selectedPropertyType);
    currentSelectedPropertyType = selectedPropertyType;
  }, [selectedPropertyType]);  

  useEffect(() => {
    currentSelectedRooms = selectedRooms;
    console.log("selected rooms",currentSelectedRooms);
  }, [selectedRooms]);

  useEffect(() => {

    if(selectedRooms.bedroom !== "0"){
      selectedBedroom = selectedRooms.bedroom;
      setSelectedBedroom(selectedRooms.bedroom);
    } else {
      selectedBedroom = "";
      setSelectedBedroom("");
    }
    console.log("selected bedrooms",selectedBedroom);

}, [selectedRooms.bedroom]);

  useEffect(() => {

    if(selectedRooms.beds !== "0"){
      selectedBeds = selectedRooms.beds;
      setSelectedBeds(selectedRooms.beds);
    } else {
      selectedBeds = "";
      setSelectedBeds("");
    }

    console.log("selected beds",selectedBeds);


  },[selectedRooms.beds]);

  useEffect(() => {

    if(selectedRooms.bathroom !== "0"){
      selectedBathroom = selectedRooms.bathroom;
      setSelectedBathroom(selectedRooms.bathroom);
    } else {
      selectedBathroom = "";
      setSelectedBathroom("");
    }
    console.log("selected bathroom",selectedBathroom);
  },[selectedRooms.bathroom])

  if (!isOpen) {
    return null;
  }

  const handlePropertyTypeChange = (newAlignment) => {
    currentSelectedPropertyType = newAlignment;
    setSelectedPropertyType(newAlignment);
  };

  const handlePriceChange = (min, max) => {
    localMinPrice = min;
    localMaxPrice = max;
    onApplyFilters({ min, max });
  };
  const handleRangeChange = (min, max) => {
    localMinRange = min;
    localMaxRange = max;
    onRangeChange({ min, max });
  };

  const handleRoomChange = (roomType, selectedButton) => {
    // console.log("room type",roomType,"selected Button",selectedButton);
    currentSelectedRooms = { ...currentSelectedRooms, [roomType]: selectedButton };
    setSelectedRooms({ ...currentSelectedRooms, [roomType]: selectedButton });

    // console.log("current selected Rooms",currentSelectedRooms);

  };

  

  return (
    <div className="filter-window">
      <h2>Filter Options</h2>
      <Divider></Divider>
      <div className="dividerr"></div>


      <div style={{marginTop: '20px', display: 'flex', justifyContent: 'center'}}>
        <Button className='fontfamily' style={{ width:'100%',fontWeight:'bold',backgroundColor:'#bb0129', color: 'white', border: '0px'}} onClick={()=>{handleOpenFilterDialog()}}>
            Set Default
        </Button>
      </div>

      {/* <Modal
        open={filterDialog}
        onClose={handleCloseFilterDialog}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Confirmation
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Are you sure you want to remove filters?
            </Typography>
            <div style={{ marginLeft: 'auto', marginTop: '20px' }}>
              <Button variant="text" sx={{ color: 'grey', marginRight: '5px' }} onClick={handleCloseFilterDialog}>Cancel</Button>
              <Button variant="text" color="primary" onClick={() => console.log("should reload the page without any filter")}>Yes, Remove Filters</Button>
            </div>
          </div>
        </Box>
      </Modal> */}


      <PropertyType  selectedValue={selectedPropertyType} onChange={handlePropertyTypeChange} />
      <div className="dividerr"></div>
      <PriceRangeInput min={localMinPrice} max={localMaxPrice} onPriceChange={handlePriceChange} />
      <div className="dividerr"></div>
      <RadiusRangeInput min={localMinRange} max={localMaxRange} onRangeChange={handleRangeChange}/>
      <div className="dividerr"></div>
      <RoomButtons
        selectedBedroomButton={selectedRooms.bedroom}
        selectedBedsButton={selectedRooms.beds}
        selectedBathroomButton={selectedRooms.bathroom}
        onRoomChange={handleRoomChange}
      />
    </div>
  );
};

export default Filters;
