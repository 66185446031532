/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import './Footer.css';
import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import { IconButton } from '@mui/material';
import LoginPopup from '../LoginSignup/LoginPopup';
import SignupPopup from '../LoginSignup/SignupPopup';




const Footer = () => {

  const [loginOpen, setLoginOpen] = useState(false);
  const [backgroundAnimated, setBackgroundAnimated] = useState(false);
  const [signupOpen, setSignupOpen] = useState(false);

  const handleSignupClose = () => {
    setSignupOpen(false);
    setBackgroundAnimated(true);
  };

  const switchToLogin = () => {
    handleSignupClose();
    handleLoginOpen();
  };

  const handleLoginOpen = () => {
    setLoginOpen(true);
    setSignupOpen(false);
  };


  const switchToSignup = () => {
    handleLoginClose();
    handleSignupOpen();
  };

  const handleSignupOpen = () => {
    setLoginOpen(false);
    setSignupOpen(true);
  };

  const handleLoginClose = () => {
    setLoginOpen(false);
    setBackgroundAnimated(false);
    // console.log("close login");
  };

  return (
    <footer className="site-footer">
      <Grid container className="footer-content">
        <Grid item lg={3} md={3} sm={12} xs={12} className="footer-left">
          <div className="logo">
            <a href="/"><img src="/LOGO-divinests-red.png" alt="Your Logo" className="img-logo" /></a>
          </div>
        </Grid>

        <Grid item lg={9} md={9} sm={12} xs={12} className="footer-middle">
          <Grid container>
            <Grid item lg={4} md={4} sm={4} xs={4} className="footer-links">
              <h1 className="footer-h1"><label>Company</label></h1>
              <ul>
                <li><a href="/">Home</a></li>
                <li><a href="/">Resources</a></li>
                <li><a href="/about">About Us</a></li>
                <li><a href="/contact">Contact</a></li>
              </ul>
            </Grid>

            <Grid item lg={4} md={4} sm={4} xs={4} className="footer-links">
              <h1 className="footer-h1"><label>Help</label></h1>
              <ul>
                <li><a href="#">Support</a></li>
                <li><a onClick={handleLoginOpen} style={{cursor:'pointer'}}>Sign in</a></li>
                <li><a onClick={handleSignupOpen} style={{cursor:'pointer'}}>Sign up</a></li>
              </ul>
            </Grid>

            <Grid item lg={4} md={4} sm={4} xs={4} className="footer-links">
              <h1 className="footer-h1"><label>Properties</label></h1>
              <ul>
                <li><a href="#">Listings</a></li>
                <li><a href="#">Featured properties</a></li>
                <li><a href="#">Rent</a></li>
              </ul>
            </Grid>
          </Grid>
        </Grid>
      </Grid>


      <Grid container className="footer-right">
        <Grid item lg={12} md={12} sm={12} xs={12} className="footer-social">
          <p>Follow Us:</p>
          <div className="footer-logos">
            <a href="https://www.facebook.com/">
              <IconButton
                style={{ color: 'black', transition: 'color 0.25s' }}
                onMouseOver={(e) => e.currentTarget.style.color = 'blue'}
                onMouseOut={(e) => e.currentTarget.style.color = 'black'}
              >
                <FacebookIcon fontSize='large' />
              </IconButton>
            </a>
            <a href="https://www.instagram.com/">
              <IconButton
                style={{ color: 'black', transition: 'color 0.25s' }}
                onMouseOver={(e) => e.currentTarget.style.color = 'blue'}
                onMouseOut={(e) => e.currentTarget.style.color = 'black'}
              >
                <InstagramIcon fontSize='large' />
              </IconButton>
            </a>
            <a href="https://twitter.com/?lang=en">
              <IconButton
                style={{ color: 'black', transition: 'color 0.25s' }}
                onMouseOver={(e) => e.currentTarget.style.color = 'blue'}
                onMouseOut={(e) => e.currentTarget.style.color = 'black'}
              >
                <TwitterIcon fontSize='large' />
              </IconButton>
            </a>

          </div>
        </Grid>
      </Grid>

      <LoginPopup isOpen={loginOpen} onClose={handleLoginClose} onSwitchToSignup={switchToSignup} />
      

      <SignupPopup isOpen={signupOpen} onClose={handleSignupClose} onSwitchToLogin={switchToLogin} />

      <div className="footer-bottom">
        <p className="bot-text">&copy; 2023 Your Real Estate. All rights reserved. Powered by <a href="https://www.eddysgroup.com/" className="powered-by">Eddy's Group</a>.</p>
      </div>
    </footer>
  );
};


    // {
    //   "src": "favicon.ico",
    //   "sizes": "64x64 32x32 24x24 16x16",
    //   "type": "image/x-icon"
    // },

export default Footer;