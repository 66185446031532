// VideoPlayer.js
import React from 'react';
import ReactPlayer from 'react-player';

const VideoPlayer = ({ url, playing, controls }) => {
//   const videoUrl = 'https://www.youtube.com/watch?v=xyz'; // Replace with your video URL

  return (
    <div className="video-player">
      <ReactPlayer 
        url={url} 
        playing={playing} // Change to true to autoplay
        controls={true} // Show controls
        width="100%" // Responsive width
        height="90%" // Responsive height
      />
    </div>
  );
};

export default VideoPlayer;
