import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Config, { API_URL } from './Services/Config';
import { Grid, Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import Calendar from 'react-calendar';
import styled from 'styled-components';
import 'react-calendar/dist/Calendar.css';
import { useNavigate } from 'react-router-dom';

// Styled component for calendar highlighting
const StyledCalendar = styled(Calendar)`
  .react-calendar__tile--active {
    background: #a0d8f1 !important;
    border-radius: 4px;
  }
  .react-calendar__tile--highlighted {
    background: #a0d8f1 !important;
    border-radius: 4px;
  }
  .react-calendar__tile {
    border-radius: 0 !important;
  }
`;

const UploadAdForm = ({ last_fourdigit ,id}) => {
  const [formState1, setFormState1] = useState({
    image: null,
    link: '',
    dateRange: [null, null],
    error: null,
    success: null,
    loading: false,
    price: 0,
    imageUrl: ''
  });

  const [formState2, setFormState2] = useState({
    image: null,
    link: '',
    dateRange: [null, null],
    error: null,
    success: null,
    loading: false,
    price: 0
  });
  const incrementedId = id + 1;
  const [imageUrl, setImageUrl] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [paymentCardNumber, setPaymentCardNumber] = useState('');
  const [paymentError, setPaymentError] = useState(null);
  
  const fileInputRef1 = useRef();
  const TOKEN_KEY = "token";
  const navigate = useNavigate();

  const uploadImage = async (formData) => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
      console.error('No authentication token found.');
      throw new Error('Authentication token missing.');
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    };
    try {
      for (let pair of formData.entries()) {
        console.log(pair[0] + ': ' + (pair[1] instanceof File ? pair[1].name : pair[1]));
      }
      const response = await axios.post(`${API_URL}/owner/uploadAdimage`, formData, config);
      console.log('Response data:', response.data);
      const newImageUrl = response.data.location;
      console.log('Location from response:', newImageUrl);
      setImageUrl(newImageUrl);
      console.log('State update triggered. ImageUrl is:', newImageUrl);
      return newImageUrl;
    } catch (error) {
      console.error('Error uploading image:', error);
      throw error;
    }
  };

  useEffect(() => {
    console.log('Updated imageUrl:', imageUrl);
  }, [imageUrl]);
  
  const calculatePrice = (startDate, endDate) => {
    if (startDate && endDate) {
      const diffTime = Math.abs(endDate - startDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays * 10;
    }
    return 0;
  };

  useEffect(() => {
    setFormState1(prevState => ({
      ...prevState,
      price: calculatePrice(prevState.dateRange[0], prevState.dateRange[1])
    }));
  }, [formState1.dateRange]);

  useEffect(() => {
    setFormState2(prevState => ({
      ...prevState,
      price: calculatePrice(prevState.dateRange[0], prevState.dateRange[1])
    }));
  }, [formState2.dateRange]);

  const handleFileChange = async (e, formState, setFormState) => {
    const file = e.target.files[0];
    if (file) {
      try {
        setFormState(prevState => ({ ...prevState, loading: true }));
        const resizedFile = await resizeImage(file);
        const formData = new FormData();
        formData.append('image', resizedFile);
        const imageUrl = await uploadImage(formData);
        console.log(imageUrl, 'Image URL from uploadImage');
        setFormState(prevState => ({
          ...prevState,
          image: URL.createObjectURL(resizedFile),
          imageUrl,
          loading: false
        }));
      } catch (error) {
        setFormState(prevState => ({
          ...prevState,
          error: 'Error uploading image. Please try again.',
          loading: false
        }));
      }
    }
  };
  
  const resizeImage = (file) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      const reader = new FileReader();
      reader.onload = (e) => {
        img.src = e.target.result;
      };
      reader.onerror = () => reject(new Error('Failed to read image file'));
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        let { width, height } = img;
        if (width > 750) {
          height *= 750 / width;
          width = 750;
        }
        if (height > 250) {
          width *= 250 / height;
          height = 250;
        }
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);
        canvas.toBlob((blob) => {
          if (blob) {
            resolve(blob);
          } else {
            reject(new Error('Failed to resize image'));
          }
        }, file.type);
      };
      reader.readAsDataURL(file);
    });
  };
  
  const handleSubmit = async (e, formState, setFormState, isFirstForm) => {
    setPaymentCardNumber(`XXXX-XXXX-XXXX-${last_fourdigit}`)
    e.preventDefault();
    setModalOpen(true);
  };

  const handleConfirmPayment = async () => {
    // Perform payment processing here if needed
    // Placeholder for now
    setPaymentError(null);
    await submitAd();
  };

  const handleCancelPayment = () => {
    setModalOpen(false);
    setPaymentCardNumber(`XXXX-XXXX-XXXX-${last_fourdigit}`)
  };

  const submitAd = async () => {
    const formState = formState1; // or formState2 depending on which form you are using
    let userId = "";
    const token = localStorage.getItem(TOKEN_KEY);
    setFormState1(prevState => ({ ...prevState, loading: true, error: null, success: null }));

    try {
        const userDetails = await Config.getUserDetails();
        console.log('User details:', userDetails);
        userId = userDetails.data.id;
        console.log('User ID:', userId);
    } catch (error) {
        console.error('Error fetching user details:', error);
        setFormState1(prevState => ({
            ...prevState,
            error: 'Failed to fetch user details. Please try again.',
            loading: false
        }));
        return;
    }

    const adData = {
        image_url: formState.imageUrl,
        start_date: formState.dateRange[0] ? formState.dateRange[0].toISOString().split('T')[0] : '',
        end_date: formState.dateRange[1] ? formState.dateRange[1].toISOString().split('T')[0] : '',
        price: parseFloat(formState.price),
        user_id: userId,
    };

    try {
        const existingAdsResponse = await axios.get(`${API_URL}/getads`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            params: {
                start_date: adData.start_date,
                end_date: adData.end_date
            }
        });

        const existingAds = existingAdsResponse.data.data;

        const overlappingAds = existingAds.filter(ad => {
            return (
                (new Date(ad.start_date) <= new Date(adData.end_date)) &&
                (new Date(ad.end_date) >= new Date(adData.start_date))
            );
        });

        if (overlappingAds.length >= 2) {
            setFormState1(prevState => ({
                ...prevState,
                error: 'Cannot create more than two ads with overlapping dates.',
                loading: false
            }));
            return;
        }
    } catch (error) {
        console.error('Error fetching existing ads:', error);
        setFormState1(prevState => ({
            ...prevState,
            error: 'Failed to check existing ads. Please try again.',
            loading: false
        }));
        return;
    }

    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    };

    try {
        const adResponse = await axios.post(`${API_URL}/owner/submitAd`, adData, config);
        console.log('Ad submission response:', adResponse.data);
        setFormState1(prevState => ({
            ...prevState,
            success: 'Ad submitted successfully!',
            loading: false,
            imageUrl: '',
            dateRange: [null, null],
        }));
        setModalOpen(false);
    } catch (error) {
        console.error('Error submitting ad:', error);
        setFormState1(prevState => ({
            ...prevState,
            error: 'Failed to submit ad. Please try again.',
            loading: false
        }));
    }
  };

  return (
    <Grid container spacing={2} style={{ padding: '20px' }}>
      <Grid item xs={12} md={6}>
        <Typography variant="h4">Upload Your Ad</Typography>
        <form onSubmit={(e) => handleSubmit(e, formState1, setFormState1, true)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div
                style={{
                  width: '100%',
                  height: '200px',
                  border: '2px dashed #ccc',
                  borderRadius: '4px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'relative',
                }}
              >
                {!formState1.image ? (
                  <Typography variant="h6" color="textSecondary">+</Typography>
                ) : (
                  <img
                    src={formState1.image}
                    alt="Uploaded"
                    style={{
                      maxWidth: '100%',
                      maxHeight: '100%',
                      objectFit: 'contain',
                    }}
                  />
                )}
                <input
                  ref={fileInputRef1}
                  type="file"
                  hidden
                  onChange={(e) => handleFileChange(e, formState1, setFormState1)}
                  accept=".jpg, .jpeg, .png"
                />
                <Button
                  variant="contained"
                  color="primary"
                  component="label"
                  style={{
                    position: 'absolute',
                    bottom: '10px',
                    right: '10px',
                  }}
                >
                  {formState1.image ? 'Change Image' : 'Upload Image'}
                  <input
                    type="file"
                    hidden
                    onChange={(e) => handleFileChange(e, formState1, setFormState1)}
                    accept=".jpg, .jpeg, .png"
                  />
                </Button>
              </div>
            </Grid>
          </Grid>
          <div style={{ marginTop: '1rem' }}>
            <StyledCalendar
              selectRange
              value={formState1.dateRange}
              onChange={(dateRange) => setFormState1(prevState => ({ ...prevState, dateRange }))}
              tileClassName={({ date }) => {
                if (date >= formState1.dateRange[0] && date <= formState1.dateRange[1]) {
                  return 'react-calendar__tile--highlighted';
                }
                return null;
              }}
            />
          </div>
          <Typography variant="h6" style={{ marginTop: '1rem' }}>
            Total Price: ${formState1.price}
          </Typography>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={formState1.loading}
            style={{ marginTop: '1rem' }}
          >
            {formState1.loading ? 'Submitting...' : 'Submit Ad'}
          </Button>
          {formState1.error && <Typography color="error" style={{ marginTop: '1rem' }}>{formState1.error}</Typography>}
          {formState1.success && <Typography color="primary" style={{ marginTop: '1rem' }}>{formState1.success}</Typography>}
        </form>
      </Grid>
      
      <Grid item xs={12} md={6}>
        <Typography variant="h4">Upload Your Ad (Alternative)</Typography>
        <form onSubmit={(e) => handleSubmit(e, formState2, setFormState2, false)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div
                style={{
                  width: '100%',
                  height: '200px',
                  border: '2px dashed #ccc',
                  borderRadius: '4px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'relative',
                }}
              >
                {!formState2.image ? (
                  <Typography variant="h6" color="textSecondary">+</Typography>
                ) : (
                  <img
                    src={formState2.image}
                    alt="Uploaded"
                    style={{
                      maxWidth: '100%',
                      maxHeight: '100%',
                      objectFit: 'contain',
                    }}
                  />
                )}
                <input
                  type="file"
                  hidden
                  onChange={(e) => handleFileChange(e, formState2, setFormState2)}
                  accept=".jpg, .jpeg, .png"
                />
                <Button
                  variant="contained"
                  color="primary"
                  component="label"
                  style={{
                    position: 'absolute',
                    bottom: '10px',
                    right: '10px',
                  }}
                >
                  {formState2.image ? 'Change Image' : 'Upload Image'}
                  <input
                    type="file"
                    hidden
                    onChange={(e) => handleFileChange(e, formState2, setFormState2)}
                    accept=".jpg, .jpeg, .png"
                  />
                </Button>
              </div>
            </Grid>
          </Grid>
          <div style={{ marginTop: '1rem' }}>
            <StyledCalendar
              selectRange
              value={formState2.dateRange}
              onChange={(dateRange) => setFormState2(prevState => ({ ...prevState, dateRange }))}
              tileClassName={({ date }) => {
                if (date >= formState2.dateRange[0] && date <= formState2.dateRange[1]) {
                  return 'react-calendar__tile--highlighted';
                }
                return null;
              }}
            />
          </div>
          <Typography variant="h6" style={{ marginTop: '1rem' }}>
            Total Price: ${formState2.price}
          </Typography>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={formState2.loading}
            style={{ marginTop: '1rem' }}
          >
            {formState2.loading ? 'Submitting...' : 'Submit Ad'}
          </Button>
          {formState2.error && <Typography color="error" style={{ marginTop: '1rem' }}>{formState2.error}</Typography>}
          {formState2.success && <Typography color="primary" style={{ marginTop: '1rem' }}>{formState2.success}</Typography>}
        </form>
      </Grid>
      <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
        <DialogTitle>Confirm Payment</DialogTitle>
        <DialogContent>
        <Typography variant="body1" color="textSecondary" style={{ marginLeft: '16px' }}>
      Invoice number: {incrementedId}
      </Typography>
        <TextField
  label="Card Number"
  value={paymentCardNumber}
  fullWidth
  margin="normal"
  error={!!paymentError}
  helperText={paymentError}
  InputProps={{
    readOnly: true,
  }}
/>

          <Typography variant="body1">Price: ${formState1.price || formState2.price}</Typography>
        </DialogContent>
        <DialogActions>
      <Button onClick={handleCancelPayment} color="primary">
        Cancel
      </Button>
      <Button onClick={handleConfirmPayment} color="primary">
        Confirm Payment
      </Button>
    </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default UploadAdForm;
