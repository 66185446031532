// importing external style
import { BsTextWrap } from "react-icons/bs";
import { styles } from "./../styles";
import { useState,useEffect,useRef } from 'react';
import React from 'react';
import '@babel/polyfill';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
//for displaying the model view/Window
import axios from "axios";
import { Result } from "antd";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { faMicrophone } from '@fortawesome/free-solid-svg-icons'; 
import { faRobot } from '@fortawesome/free-solid-svg-icons'; // Icon for bot
import { faUser } from '@fortawesome/free-solid-svg-icons'; // Icon for user
import Config, { PYTHON_URL } from "../../../Services/Config";
import SpeechModal from "./SpeechModal";
import SoundPermission from "./SoundPermission";



function ModalWindow(props) {
    let voices = [];
    window.speechSynthesis.onvoiceschanged = () => {
         voices = speechSynthesis.getVoices();
        // Optionally, store voices for later use
        console.log("window.speechSynthesis.onvoiceschanged", voices);
        

    };
    const [msgs, setMsgs] = useState([{sender: 0, message:'hello'}]);
    const { transcript, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();
    const [isListening, setIsListening] = useState(false);
    let [text,setText]=useState('');
    const [audiotext,setaudiotext]=useState ('Audio');
    let { setLatestMsg, latestMsg , onIdsUpdate } = props;
    const [audioUrl, setAudioUrl] = useState('');
    const msgContainerRef = useRef(null);
    const [speechEnabled, setSpeechEnabled] = useState(false);
    const handleEnableSpeech = () => {
        setSpeechEnabled(true); // Enable speech synthesis
      };
  
    const inputRef = useRef(null); // Create a ref for the input element
    const buttonStyle = {
       padding:'10px 6px',
        borderRadius: '5px',
        border: '1px solid #ccc',
        backgroundColor: '#ffffff',
        cursor: 'pointer',
        outline: 'none',
      };

      

    
      useEffect(() => {
        if (inputRef.current) {
          inputRef.current.focus(); // Focus the input field on load
          inputRef.current.click(); // Simulate a mouse click
    
          // Wait for 2 seconds before starting typing
        //   setTimeout(() => {
        //     // Simulate typing the word 'hello'
        //     const text = 'hello';
        //     let index = 0;
    
        //     const typeLetter = () => {
        //       if (index < text.length) {
        //         inputRef.current.value += text[index]; // Add one letter to the input
        //         index++;
                
        //         // Dispatch input event to reflect the change in the input field
        //         const inputEvent = new Event('input', { bubbles: true });
        //         inputRef.current.dispatchEvent(inputEvent);
    
        //         // Continue typing each letter with a slight delay
        //         setTimeout(typeLetter, 150); // Adjust the typing speed (150ms delay)
        //       } else {
        //         // After typing 'hello', simulate an 'Enter' key press
        //         const enterEvent = new KeyboardEvent('keydown', {
        //           key: 'Enter',
        //           keyCode: 13,
        //           code: 'Enter',
        //           which: 13,
        //           bubbles: true,
        //           cancelable: true,
        //         });
    
        //         // Dispatch the 'Enter' key event
        //         inputRef.current.dispatchEvent(enterEvent);
        //       }
              
        //     };
    
        //     // Start typing the word 'hello'
        //     typeLetter();
        //   }, 2000); // Wait for 2 seconds before starting to type
        }
      }, []); // Run only once on mount
    // Simulate a mouse click event on page load
    // useEffect(() => {
    //     const simulateClick = () => {
    //         if (inputRef.current) {
    //             console.log("Simulating a click on the target button");

    //             // Dispatch a click event on the target button
    //             inputRef.current.click();
    //         } else {
    //             console.warn("Target button not found");
    //         }

    //         // Remove the event listener after the click
    //         window.removeEventListener('load', simulateClick);
    //     };

    //     // Add the event listener for the page load event
    //     window.addEventListener('load', simulateClick);

    //     return () => {
    //         window.removeEventListener('load', simulateClick);
    //     };
    // }, []);


      
      function ModalWindow(props) {
          // Component code...
      }
    
      useEffect(() => {
        if(latestMsg=="111111"){
            console.log("latestMsg",latestMsg);
            
            tryToSpeak("Hello")
            setText('')
            latestMsg='';
            return
        }
        if(latestMsg!=""){
            text=latestMsg
            handleClick();

        latestMsg='';
        setText('')
        }
        // Perform actions with latestMsg, such as fetching data, updating state, etc.
      }, [latestMsg]);
     
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            props.stopListening()
            handleClick();
            
         
        }
    };

    const handleClick = async () => {
        if (text !== '') {
            // Add the text to msgs state
            setMsgs(prevMsgs => [...prevMsgs, { sender: 1, message: text }]);
            // send the text to the python server
            handleMessageSubmit();
            
            // Clear the text
            setText('');
        }
    };
    // let availableVoices = [];

    // // Load voices when they change
    // speechSynthesis.onvoiceschanged = () => {
    //     console.log("check");
        
    //     availableVoices = speechSynthesis.getVoices();
    //     console.log(availableVoices);
        
    // };

    function loadVoices() {
        return new Promise((resolve) => {
            let voices = speechSynthesis.getVoices();
            if (voices.length > 0) {
                resolve(voices);
            } else {
                speechSynthesis.onvoiceschanged = () => {
                    voices = speechSynthesis.getVoices();
                    resolve(voices);
                };
            }
        });
    }
    
    
    // Helper function to attempt speaking
    // const tryToSpeak = async (message, retryCount = 0) => {
    //     if (!window.speechSynthesis) {
    //         console.error("Speech Synthesis not supported.");
    //         return;
    //     }
    //     console.log(window.speechSynthesis);
        
    //     if (retryCount > 2) {
    //         console.warn("Failed to initiate speech synthesis after retries.");
    //         props.startListening(); // Resume listening if retries are exhausted
    //         return;
    //     }
    //     console.log("message is ",message);
        
    //     const utterance = new SpeechSynthesisUtterance(message);
    //     const voices = await loadVoices();

    //     let selectedVoice = voices.find(voice => voice.voiceURI === "Microsoft Zira - English (United States)"); 
    //     // Fallback to the first voice if the specific one isn't found
    //     console.log(selectedVoice);
    //     if (!selectedVoice) {
    //         console.warn("Selected voice not found. Falling back to the first available voice.");
    //         selectedVoice = voices[0];
    //     }
    //     console.log(selectedVoice);
    //     if (selectedVoice) {
    //         console.log("Using voice:", selectedVoice.name);
    //         speechSynthesis.cancel();  
    //         utterance.voice = selectedVoice;
    //         utterance.lang = selectedVoice.lang || 'en-US'; // Ensure language is set
    //         utterance.rate = 1; // Set a neutral speaking rate
    //         if (speechSynthesis.speaking) {
    //             console.log("speechSynthesis is currently speaking, canceling to restart.");
    //             speechSynthesis.cancel();
    //         }

    //         props.stopListening();
    //         console.log("now speak in handleMessageSubmit");
    //         console.log("utterance ",utterance);
    //         console.log("speechSynthesis ",speechSynthesis);
    //         utterance.onstart = function() {
    //             console.log("Speech synthesis started.");
    //         };       
    //         utterance.onend = function(event) {
    //             console.log("Speech ended, resuming listening.");
    //             // Resume listening only after speech synthesis ends
    //             props.startListening();
    //         };
                   
                    
                   
    //     } else {
    //         console.error('Voice not found.');
    //     }

    //     // Attempt to speak
    //     console.log(`Attempt ${retryCount + 1} to speak`);
    //     // speechSynthesis.speak(utterance);
    //     setTimeout(() => {
    //         console.log("now speak in handleMessageSubmit");
    //         speechSynthesis.speak(utterance);
    //     }, 100); // Short delay
    //     console.log("speechSynthesis is", speechSynthesis.speaking);
        
    //     if (!speechSynthesis.speaking) {
    //         // Retry speaking after a brief delay
    //         setTimeout(() => {
    //             tryToSpeak(message, retryCount + 1);
    //         }, 1500); // 200ms delay between retries
    //     }
    // };

    const tryToSpeak = async (message, retryCount = 0) => {
        
        if (retryCount > 2) {
            console.warn("Failed to initiate speech synthesis after retries.");
            props.startListening(); // Resume listening if retries are exhausted
            return;
        }
        const ut = new SpeechSynthesisUtterance('No warning should arise');
        speechSynthesis.speak(ut);
        console.log("message is ", message);
        // const utterance = new SpeechSynthesisUtterance(message);
        const utterance = new window.SpeechSynthesisUtterance(message);

        const voices = await loadVoices();
        let selectedVoice = voices.find(voice => voice.voiceURI === "Microsoft Zira - English (United States)");
        if (!selectedVoice) {
            console.warn("Selected voice not found. Falling back to the first available voice.");
            selectedVoice = voices[0];
        }
        
        if (selectedVoice) {
            console.log("Using voice:", selectedVoice.name);
            window.speechSynthesis.cancel();  
            utterance.voice = selectedVoice;
            utterance.lang = selectedVoice.lang || 'en-US';
            utterance.rate = 1;
    
            props.stopListening();
            utterance.onstart = () => {
                console.log("Speech synthesis started.");
            };       
    
            utterance.onend = () => {
                console.log("Speech ended, resuming listening.");
                props.startListening();
            };
    
            window.speechSynthesis.speak(utterance);
            
            if (!window.speechSynthesis.speaking) {
                setTimeout(() => {
                    tryToSpeak(message, retryCount + 1);
                }, 1000); // Longer delay for retry
            }
        } else {
            console.error('Voice not found.');
        }
    };
  
    const handleMessageSubmit = async () => {
        try {
            // const response = await fetch(`http://127.0.0.1:5000/answer/${encodeURIComponent(text)}`, {
                
            const storedToken = localStorage.getItem('chat_token');
            const response = await fetch(`${PYTHON_URL}answer/${encodeURIComponent(text)}?token=${storedToken}`, {

                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
    
            if (!response.ok) {
                console.log(`HTTP error! Status: ${response.status}`);

                throw new Error(`HTTP error! Status: ${response.status}`);
            }


            const resultText = await response.text();
            const responseData = JSON.parse(resultText);

            // Select a random response from the server's response array
            const responses = responseData.responses;
            const parts = responses.split("!!!!!!!!!IDs:");
            const properties = parts[0].trim(); // "Properties: dawra beirut."
            // const ids = parts[1] ? parts[1].trim().split(",").map(id => id.trim()) : []; // Assuming IDs are comma-separated
            const ids = parts[1] ? parts[1] : "-1"; // Assuming IDs are comma-separated
            
            onIdsUpdate(ids);
            // const randomIndex = Math.floor(Math.random() * responses.length);
            // let messageToSend = properties;
            let messageToSend = properties || "I can't understand your question";

           
            // Speak the response
            if (messageToSend !== "I can't understand your question") {
                tryToSpeak(messageToSend);
                console.log("after speechSynthesis.speak, speaking state:", speechSynthesis.speaking);

                
            }
            // Update msgs state with the response
            setMsgs(prevMsgs => [...prevMsgs, { sender: 0, message: messageToSend }]);

            props.setLatestMsg(text)
            setText('')
        }catch (error) {
            console.error('Error:', error);
        }
    };

    
    
     useEffect(() => {
        if (props.latestMsg == "") {
            setText('');
            props.setLatestMsg("");
        }
    }, [props.latestMsg]);
    
    
   /////////////////////////////////////////////////////////////////////////////

   useEffect(() => {
    // Update the audio when audioUrl changes
    if (audioUrl) {
      const audioElement = document.getElementById('audioPlayer');
      audioElement.load();
    }
  }, [audioUrl]);
   
    useEffect(() => {
        let silenceTimer;
        const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    
        // Check if the browser language is set to Arabic
        const isArabic = navigator.language.startsWith("ar");
      
       
        const startListeningArabic = () => {
          SpeechRecognition.startListening({ continuous: true, language: 'ar-SA' });
      };
      const startListeningEnglish = () => {
          SpeechRecognition.startListening({ continuous: true, language: 'en-US' });
      };
        if (isListening) {
           
                //startListeningArabic();
            
                startListeningEnglish();
            silenceTimer = setTimeout(() => {
                setIsListening(false);
                SpeechRecognition.stopListening();
                let messagetext = transcript.replace(/\./g, '');
                setText(messagetext);
                setLatestMsg(messagetext);
                console.log("the transcript: " + messagetext)
                
                setaudiotext('Audio');
            }, 2000);
        } else {
            SpeechRecognition.stopListening();
            handleClick();
            clearTimeout(silenceTimer);
        }
        return () => {
            clearTimeout(silenceTimer);
        };
    }, [ isListening, transcript]);
    const startListening = () => {
        setaudiotext('speak!');
        setIsListening(true);
        resetTranscript();
    };
    // deQueue function that will be called on both click and mousemove
    // const deQueue = () => {
    //     console.log('Event triggered (click or mousemove)');
    // };

    // useEffect(() => {
    //     // Attach event listeners to document
    //     document.addEventListener('click', deQueue);
    //     document.addEventListener('mousemove', deQueue);

    //     // Cleanup event listeners when the component is unmounted or dependencies change
    //     return () => {
    //     document.removeEventListener('click', deQueue);
    //     document.removeEventListener('mousemove', deQueue);
    //     };
    // }, []); // Empty dependency array to run only once on mount

    // return <div>Click or move the mouse to trigger the deQueue function.</div>;
    // };
    useEffect(() => {
        // Scroll to bottom of messages container whenever msgs state updates
        if (msgContainerRef.current) {
            msgContainerRef.current.scrollTop = msgContainerRef.current.scrollHeight;
        }
    }, [msgs]);

    useEffect(() => {
        speechSynthesis.cancel();
        console.log("Speech synthesis reset at startup");
        props.stopListening();
    }, []);

    
    if (!browserSupportsSpeechRecognition) {
        return null;
    }

    


    
    return (
        <>
            {/* {!speechEnabled && <SpeechModal onEnableSpeech={handleEnableSpeech} />} */}
            {/* { <SoundPermission  /> } */}

            
        <div
        style={{
            ...styles.modalWindow,
            backgroundColor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent background
            position: 'fixed',
            overflow: 'hidden',
            display: props.visible ? 'flex' : 'none', // Display the modal only if visible
            flexDirection: 'column',
            zIndex: 1000, // Ensure modal is above other content
        }}
    >
        {/* <button onClick={() => tryToSpeak("Hello")} style={{backgroundColor:'green',color:'white',padding: '10px 10px', border: 'none',borderRadius: '5px',fontSize:'10px',}}>
                    <FontAwesomeIcon icon={faPaperPlane} />
        </button> */}
          <div style={{ backgroundColor: '#bb0129', padding: '5px', textAlign: 'center', fontWeight: 'bold', color: 'white' }}>AI ROBOT AND CHATBOT</div>
    <div style={{ display: 'flex', backgroundColor: '#bb0129', paddingLeft: '120px', textAlign: 'right', color: 'white', fontSize: '10px' }}>
         <div style={{ flex: '1', textAlign: 'right', paddingRight: '5px', fontSize: '10px' }}>POWERED BY</div>
        <div style={{ flex: '1', textAlign: 'left', fontWeight: 'bold', fontSize: '10px' }}>EDDY'S GROUP</div>
    </div>

            <div ref={msgContainerRef} style={{ overflowY: 'auto', maxHeight: '30vh' }}>
                {msgs.map((msg, index) => (
                    <div key={index} style={{ display: 'flex', justifyContent: msg.sender === 1 ? 'flex-end' : 'flex-start', alignItems: 'flex-start', marginTop: '10px' }}>
                        {msg.sender === 0 ? (
                            <div style={{ marginRight: '10px', display: 'flex', alignItems: 'center' }}>
                                <FontAwesomeIcon icon={faRobot} style={{ fontSize: '24px', padding: '5px', color: '#333' }} />
                                <p style={{ backgroundColor: '#f0f0f0', color: '#333', padding: '10px', borderRadius: '12px', maxWidth: '70%' }}>{msg.message}</p>
                            </div>
                        ) : (
                            <div style={{ marginLeft: '10px', display: 'flex', alignItems: 'center' }}>
                                <p style={{ backgroundColor: '#bb0129', color: 'white', padding: '10px', borderRadius: '12px', maxWidth: '70%' }}>{msg.message}</p>
                                <FontAwesomeIcon icon={faUser} style={{ fontSize: '24px', padding: '5px', color: 'red' }} />
                            </div>
                        )}
                    </div>
                ))}
            </div>
            <div style={{ position: 'absolute', bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px', width: '100%', padding: '10px' }}>
                <input
                    type='text'
                    ref={inputRef}
                    style={{
                        width: '190px',
                        height: '30px',
                        padding: '5px',
                        border: '1px solid #ccc',
                        borderRadius: '5px',
                        fontSize: '16px',
                        outline: 'none',
                    }}
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    onKeyPress={handleKeyPress}
                />
                <button onClick={handleClick} style={{backgroundColor:'green',color:'white',padding: '10px 10px', border: 'none',borderRadius: '5px',fontSize:'10px',}}>
                    <FontAwesomeIcon icon={faPaperPlane} />
                </button>
                <button
    onClick={() => {
        if (props.recording) {
            props.stopListening();
        } else {
            props.startListening();
        }
    }}
    style={{
        backgroundColor: props.recording ? 'red' : 'green',
        color: 'white',
        border: 'none',
        padding: '10px 10px',
        borderRadius: '5px',
        cursor: 'pointer',
        outline: 'none',
        fontSize:'11px',
    }}
>
  
    <FontAwesomeIcon icon={faMicrophone} />
</button>
            </div>
            {audioUrl && (
                <audio id="audioPlayer" autoPlay>
                    <source src={require(`${audioUrl}`)} type="audio/mpeg" />
                </audio>
            )}
        </div>
        </>
    );
}


export default ModalWindow;