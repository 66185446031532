import React, { useState } from "react";
import "./Login2.css";
import axios, { AxiosHeaders } from "axios";
import AuthService from "../Services/AuthService";
import SnackbarComponent from "../SnackBar/SnackBar";
import { Button, TextField, Snackbar } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import AppleIcon from '@mui/icons-material/Apple';
import EmailIcon from '@mui/icons-material/Email';

const Login = ({ openLoginForm , closeLogInPage , openForgetPassword }) => {
  const [email, setEmail] = useState("");


  const [password, setPassword] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");

  var loginResponseMessage;

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const body = {
        email: email,
        password: password,
      };

      AuthService.login(body).then(
        (response) => {
          let loginResponseMessage, severity;

          if (response.success) {
            loginResponseMessage = "Login Successful";
            severity = "success";
            setTimeout(() => {
              window.location.reload(false);
          }, 500);
          } else {
            loginResponseMessage = response.message;
            severity = "error";
          }

          setOpenSnackbar(true);
          setSnackbarMessage(loginResponseMessage);
          setSnackbarSeverity(severity);

          console.log(loginResponseMessage);
        },
        (error) => {
          console.log("Login failed", error);
        }
      );
    } catch (error) {
      console.error(error);
    }
  };
  const handleSocialLogin = (platform) => {
    console.log(`${platform} login clicked`);
    
    // Handle social login (Google, Facebook, etc.)
  };
  const logout = async () => {
    if (AuthService.getToken() != null) {
      const response = await AuthService.logout();
      console.log(response.message);
    } else {
    }
  };

  // return ( before 15-11-2024
  //   <div className="login-popup">
  //     <div className="text fontfamily">Login</div>
  //     <div className="close-btn">
  //         <button className="close-button" onClick={closeLogInPage}>
  //             x
  //           </button>
  //         </div>
  //     <form onSubmit={handleLogin} style={{overflowY:'auto'}} >
        
  //       <div className="form-container fontfamily">
  //       <div className="form-group " >
          
  //         <label htmlFor="email" className="login-label">
  //           Email *
  //         </label>
  //         <input
  //           type="email"
  //           id="email"
  //           value={email}
  //           onChange={(e) => setEmail(e.target.value)}
  //           required
  //           className="login-input"
  //           placeholder="E-mail"
  //         />
  //       </div>
  //       <div className="form-group">
  //         <label htmlFor="password" className="login-label">
  //           Password *
  //         </label>
  //         <input
  //           type="password"
  //           id="password"
  //           value={password}
  //           onChange={(e) => setPassword(e.target.value)}
  //           required
  //           className="login-input"
  //           placeholder="Password"
  //         />
  //       </div>

  //       <Button type="submit" variant="container" className="login-btn fontfamily">
  //         Login
  //       </Button>

  //       <SnackbarComponent
  //         open={openSnackbar}
  //         setOpen={setOpenSnackbar}
  //         message={snackbarMessage}
  //         severity={snackbarSeverity}
  //       />
  //       <div style={{display:'flex',alignItems:'center'}}>
  //         <label style={{marginRight:'5px'}} >Don't have an account?{" "}</label>
  //         <a href
  //           onClick={
  //             openLoginForm}
  //           style={{cursor:'pointer'}}
  //         >
  //           Signup
  //         </a>
  //       </div>

        

  //       </div>

        
  //     </form>
  //     {/* <button onClick={logout}>Logout</button> */}
  //      {/* Horizontal line with "or" */}
  //       <div className="separator">
  //         <span className="line"></span>
  //         <span className="or">or</span>
  //         <span className="line"></span>
  //       </div>

  //       {/* Social and Email Buttons */}
  //       <div className="social-buttons">
  //         <button className="social-btn facebook-btn">Login with Facebook</button>
  //         <button className="social-btn google-btn">Login with Google</button>
  //         <button className="social-btn apple-btn">Login with Apple</button>
  //         <button className="social-btn email-btn">Login with Email</button>
  //       </div>
  //   </div>
  // );
  return (
    <div className="login-form-container fontfamily">
      
      <div className="header-container">
        <h5>Log in or sign up</h5>
        <div className="close-btn">
          <button className="close-button" onClick={closeLogInPage}>
            x
          </button>
        </div>
      </div>
      <form onSubmit={handleLogin} className="login-form">
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={{ marginBottom: '16px' }}
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={{ marginBottom: '16px' }}
        />
        <Button  className="login-btn fontfamily" type="submit" variant="contained" fullWidth style={{ marginBottom: '16px' }}>
          Log In
        </Button>
      </form>
        <div style={{display:'flex',alignItems:'center'}}>
           <label style={{marginRight:'5px'}} >Forget password?{" "}</label>
           <a href
             onClick={
               openForgetPassword}
             style={{cursor:'pointer',   color: '#0077FF'}}
           >
             reset now
           </a>
           
         </div>
         <div style={{display:'flex',alignItems:'center'}}>
           <label style={{marginRight:'5px'}} >Don't have an account?{" "}</label>
           <a href
             onClick={
               openLoginForm}
             style={{cursor:'pointer',   color: '#0077FF'}}
           >
             Signup
           </a>
           
         </div>
         
          <div className="separator">
            <span className="line"></span>
            <span className="or">or</span>
            <span className="line"></span>
          </div>
      <div className="social-login-buttons">
        <Button
          variant="outlined"
          color="primary"
          startIcon={<GoogleIcon />}
          fullWidth
          onClick={() => handleSocialLogin('Google')}
          style={{ marginBottom: '8px' }}
        >
          Continue with Google
        </Button>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<FacebookIcon />}
          fullWidth
          onClick={() => handleSocialLogin('Facebook')}
          style={{ marginBottom: '8px' }}
        >
          Continue with Facebook
        </Button>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<AppleIcon />}
          fullWidth
          onClick={() => handleSocialLogin('Apple')}
          style={{ marginBottom: '8px' }}
        >
          Continue with Apple
        </Button>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<EmailIcon />}
          fullWidth
          onClick={() => handleSocialLogin('Email')}
        >
          Continue with Email
        </Button>
      </div>

      <Snackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        message={snackbarMessage}
        autoHideDuration={3000}
      />
    </div>
  );
};

export default Login;
