import React, { useState, useEffect } from "react";
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import Config from "../../../Services/Config"; // Update with the correct path
import UploadAdForm from "../../../UploadAdForm";

const AdvertisementList = ({ last_fourdigit }) => {
  const [ads, setAds] = useState([]);
  const [showUploadAdForm, setShowUploadAdForm] = useState(false);
  const [lastAdId, setLastAdId] = useState(null); // State to hold the last ad id

  const handleToggleUploadAdForm = () => {
    setShowUploadAdForm(prevState => !prevState);
  };

  useEffect(() => {
    const fetchAds = async () => {
      try {
        const user = await Config.getUserDetails();
        if (user.data.id) {
          const id = user.data.id;
          const response = await Config.getAdsByUser(id); // Replace with actual API call
          const fetchedAds = response.data;
          setAds(fetchedAds);

          // Determine the last ad's id and set it
          if (fetchedAds.length > 0) {
            setLastAdId(fetchedAds[fetchedAds.length - 1].id);
            console.log(lastAdId)
          }
        }
      } catch (error) {
        console.error('Error fetching ads:', error);
      }
    };

    fetchAds();
  }, []);

  return (
    <>
      <Box sx={{ padding: 2 }}>
        <Typography variant="h6" gutterBottom>
          Advertisements
        </Typography>
        {ads.length === 0 ? (
          <Typography variant="body1" color="textSecondary">
            No data available or history.
          </Typography>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>No.</TableCell>
                  <TableCell>Image</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Start Date</TableCell>
                  <TableCell>End Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ads.map((ad, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      <img src={ad.image_url} alt={`Ad ${index}`} style={{ width: '100px', height: 'auto' }} />
                    </TableCell>
                    <TableCell>${ad.price}</TableCell>
                    <TableCell>{ad.start_date}</TableCell>
                    <TableCell>{ad.end_date}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>

      <div style={{ marginTop: '20px' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleToggleUploadAdForm}
        >
          {showUploadAdForm ? 'Hide Upload Ad Form' : 'Show Upload Ad Form'}
        </Button>

        {showUploadAdForm && (
          <UploadAdForm
            last_fourdigit={last_fourdigit}
            id={lastAdId} // Pass the last ad's id to the form
          />
        )}
      </div>
    </>
  );
};

export default AdvertisementList;
