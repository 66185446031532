import React, { useState } from 'react';
import './PaymentMethod.css'; // Import your stylesheet
import { Modal, Box } from '@mui/material';
import SnackbarComponent from "../../../SnackBar/SnackBar";
import Config from '../../../Services/Config';

const PaymentMethod = ({ closeAddPayment, openLoginForm }) => {

  // console.log("sign up")

  const [cardName, setCardName] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [CvcTo, setCvcTo] = useState('');
  const [cardType, setCardType] = useState('');

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");

  // const handleSignup = (e) => {
  //   e.preventDefault();
  //   // Implement your signup logic here
  //   console.log('Signup button clicked!');
  //   console.log('Username:', username);
  //   console.log('Email:', email);
  //   console.log('Password:', password);

  const hanldeNumberCard = (event) => {
    let inputValue = event.target.value;

    if (inputValue === "") {
      setCardNumber("");
    } else {
      // const parsedValue = parseInt(inputValue, 10);
      if (!isNaN(parseInt(inputValue)) && inputValue.length > 0) {
        setCardNumber(inputValue);
      }
    }
  };
  const handleDateChange = (e) => {
    const inputDate = e.target.value; // Format: YYYY-MM-DD
    if (inputDate) {
      // Extract year and month from input date
      const [year, month] = inputDate.split('-');
      // Construct the date string in the required format
      const formattedDate = `${year}-${month}-01T00:00:00.000000Z`;
      setExpiryDate(formattedDate);
    } else {
      setExpiryDate('');
    }
  };
  useState(() => {
    setCardName('');
    setCardNumber('');
    setExpiryDate('');
    setCvcTo('');
    setCardType('');
  });



  //   // Add your signup API call or other logic here
  // };


  const handleAddPayment = async (e) => {

    e.preventDefault();
    // closeAddPayment();

   

       
    try {
      const body = {
        name : cardName,
        number : cardNumber,
        expiry: expiryDate,
        cvc: CvcTo,
      };
       console.log(body)
      const result = await Config.addCard(body);
      // console.log(result);
      
      if (result && result.message) {
        // Assuming 'message' is returned on error
        setSnackbarMessage(result.message);
        setSnackbarSeverity("error");
        setOpenSnackbar(true);

      } else {
        // Assuming that a successful result does not contain an 'error' key
        setSnackbarMessage("Added successfully");
        setSnackbarSeverity("success");
        setOpenSnackbar(true);
        setTimeout(() => {
          window.location.reload();
        }, 3000);

      }
      

    } catch (error) {
      console.error(error);
    }
  };

  return (
    
    <div className="payment-method-form">
      <SnackbarComponent
          open={openSnackbar}
          setOpen={setOpenSnackbar}
          message={snackbarMessage}
          severity={snackbarSeverity}
        />
      <div className="container">
        <div className="header">
          <h2>Payment Method</h2>
          <button className="close-button" onClick={closeAddPayment}>
            ×
          </button>
        </div>

        <form onSubmit={handleAddPayment} className="form-container">

          <div className="form-group">
            <label htmlFor="cardName" className="form-label">
              Name on Card *
            </label>
            <input
              type="text"
              id="cardName"
              value={cardName}
              onChange={(e) => setCardName(e.target.value)}
              className="form-input"
              placeholder="Max length: 20 characters"
              required
              maxLength={20}
            />
          </div>

          <div className="form-group">
            <label htmlFor="cardNumber" className="form-label">
              Card Number *
            </label>
            <input
              type="tel"
              id="cardNumber"
              value={cardNumber}
              onChange={(e) => setCardNumber(e.target.value)}
              className="form-input"
              placeholder="Max length: 12 digits"
              required
              maxLength={12}
            />
          </div>

          <div className="form-group">
      <label htmlFor="expiry" className="form-label">
        Expiry Date *
      </label>
      <input
        type="month" // Allows only month and year selection
        id="expiry"
        value={expiryDate ? expiryDate.slice(0, 7) : ''} // Display only YYYY-MM

        onChange={handleDateChange}
        className="form-input"
        required
      />
    </div>

          <div className="form-group">
            <label htmlFor="cvcTo" className="form-label">
              CVC *
            </label>
            <input
              type="tel"
              id="cvcTo"
              value={CvcTo}
              onChange={(e) => setCvcTo(e.target.value)}
              className="form-input"
              placeholder="123"
              required
              maxLength={3}
              pattern="\d{3,4}"
            />
          </div>

          <button type="submit" className="submit-button">
            Add
          </button>

          <SnackbarComponent
          open={openSnackbar}
          setOpen={setOpenSnackbar}
          message={snackbarMessage}
          severity={snackbarSeverity}
        />

          
        </form>
      </div>

      <style jsx>{`
        .payment-method-form {
          // font-family: Arial, sans-serif;
          max-width: 500px;
          margin: 0 auto;
          // padding: 20px;
          border-radius: 8px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          background-color: #fff;
        }
        .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;
        }
        .header h2 {
          margin: 0;
          font-size: 24px;
        }
        .close-button {
          background: none;
          border: none;
          font-size: 24px;
          cursor: pointer;
          color: #888;
        }
        .close-button:hover {
          color: #000;
        }
        
        .form-container {
          display: flex;
          flex-direction: column;
          width: 85%;
        }
        .form-group {
          margin-bottom: 15px;
        }
        .form-label {
          font-size: 14px;
          color: #333;
          margin-bottom: 5px;
          display: block;
        }
        .form-input {
          width: 100%;
          padding: 10px;
          border: 1px solid #ccc;
          border-radius: 4px;
          font-size: 14px;
          box-sizing: border-box;
        }
        .form-input:focus {
          border-color: #007bff;
          outline: none;
          box-shadow: 0 0 0 2px rgba(38, 143, 255, 0.2);
        }
        .submit-button {
          padding: 10px 20px;
          border: none;
          border-radius: 4px;
          background-color: #007bff;
          color: #fff;
          font-size: 16px;
          cursor: pointer;
          transition: background-color 0.3s;
        }
        .submit-button:hover {
          background-color: #0056b3;
        }
      `}</style>
    </div>
  );
};

export default PaymentMethod;
