import React, { useState, useEffect } from "react";
import Config from "../../Services/Config";
import { Box, Typography } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

import PropTypes from 'prop-types';
import Button from "@mui/material/Button";
import Grid from '@mui/material/Grid';
import './Owner.css';
import { FormControl, Radio, RadioGroup } from '@mui/material';

import { styled } from '@mui/material/styles';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TextField from "@mui/material/TextField";
import { Unstable_NumberInput as BaseNumberInput } from '@mui/base/Unstable_NumberInput';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#bb0129", // Color for the border when focused
    },
  },
  "& .MuiInputLabel-root": {
    // Style for the label
    color: "grey", // Default label color
    "&.Mui-focused": {
      color: "#bb0129", // Color for the label when focused
    },
  },
});
const disabledDate = (current) => {
    // Disable dates before today
    return current && current < moment().startOf('day');
  };
function TabPanel(props) {
   
    
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
;


const SponserProperty = ({
  propertyDetails,
  handleCloseSponsorDialog,
  handleStartDateChange,
  handleEndDateChange,
  selectedPropertyId
}) => {
  
  const [propertyData, setPropertyData] = useState({
    action: '',
    name: '',
    price: '',
    startDate: '',
    endDate: '',
 
  });
  const [properties, setProperties] = useState({
    action: '',
    name: '',
    price: '',
    startDate: '',
    endDate: '',

  });
  const [sponserPrice, setSponserPrice] = useState('');

  
 
 
  useEffect(() => {
    
  }, [propertyDetails]);

  const [loading, setLoading] = useState(true);

  
  const [dateRange, setDateRange] = React.useState([null, null]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [sponsorPeriods, setSponsorPeriods] = useState([]); // For view table data
  const [showTable, setShowTable] = useState(false); // To toggle view of table
  useEffect(() => {
    if (startDate && endDate) {
      const days = dayjs(endDate).diff(dayjs(startDate), 'day');
      if (days >= 0) {
        const newPrice = (days * sponserPrice).toFixed(2); // Multiply by 20 and round to 2 decimal places
        setPropertyData(prevData => ({
          ...prevData,
          price: newPrice.toString(),
        }));
      }
    }
  }, [startDate, endDate]);

  // Assuming this data comes from a fetch request or state
  useEffect(() => {
    const fetchSponsorPeriods = async () => {
        try {
            console.log(selectedPropertyId);
            
            // Fetch the sponsor periods data
            const result = await Config.getOwnerSponserId(selectedPropertyId);
            
            // Assuming result.data contains the sponsor periods
            setSponsorPeriods(result.data);

            console.log(result.data);

        } catch (error) {
            console.error("Error fetching sponsor periods data:", error);
        } finally {
            setLoading(false); // Set loading to false after data is fetched or if there's an error
        }
    };

    // Call the fetchSponsorPeriods function
    if (selectedPropertyId) {
        fetchSponsorPeriods();
    }
}, [selectedPropertyId]); // Add selectedPropertyId as a dependency to refetch data when it changes


  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const result = await Config.getOwnerProperties();
        
        setProperties(result.data);
        console.log(result.data);
      } catch (error) {
        console.error("Error fetching profile data:", error);
      } finally {
        setLoading(false); // Set loading to false whether the fetch is successful or not
      }
    };

    fetchProperties();
  }, []);

  useEffect(() => {
    const fetchSponsorPrice = async () => {
      try {
        const result = await Config.getSponsorPrice();
        
        setSponserPrice(result.data);
        console.log(result.data);
      } catch (error) {
        console.error("Error fetching profile data:", error);
      } finally {
        setLoading(false); // Set loading to false whether the fetch is successful or not
      }
    };

    fetchSponsorPrice();
  }, []);

  const handleAdd = () => {
    // Logic to add a new sponsor period
    console.log("Add new sponsor period", { startDate, endDate, price: propertyData.price });
    // Add to the sponsorPeriods array
    setSponsorPeriods([...sponsorPeriods, { from_date: startDate, to_date: endDate, price: propertyData.price }]);
  };

  const handleView = () => {
    setShowTable(!showTable); // Toggle table visibility
  };



  const blue = {
    100: '#daecff',
    200: '#b6daff',
    300: '#66b2ff',
    400: '#3399ff',
    500: '#007fff',
    600: '#0072e5',
    700: '#0059B2',
    800: '#004c99',
  };

  const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
  };

  const StyledInputRoot = styled('div')(({ theme }) => `
    // font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[500]};
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  `);

  const StyledInput = styled('input')(({ theme }) => `
    font-size: 0.875rem;
    // font-family: inherit;
    font-weight: 400;
    line-height: 1.375;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 4px ${theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.5)' : 'rgba(0,0,0, 0.05)'
    };
    border-radius: 8px;
    margin: 0 8px;
    padding: 10px 12px;
    outline: 0;
    min-width: 0;
    width: 3rem;
    height: 1rem;
    text-align: center;
  
    &:hover {
      border-color: ${blue[400]};
    }
  
    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[700] : blue[200]};
    }
  
    &:focus-visible {
      outline: 0;
    }
  `);

  const StyledButton = styled('button')(({ theme }) => `
    // font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    box-sizing: border-box;
    line-height: 1.5;
    border: 1px solid;
    border-radius: 999px;
    border-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
    color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
    width: 26px;
    height: 26px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 120ms;
  
    &:hover {
      cursor: pointer;
      background: ${theme.palette.mode === 'dark' ? blue[700] : blue[500]};
      border-color: ${theme.palette.mode === 'dark' ? blue[500] : blue[400]};
      color: ${grey[50]};
    }
  
    &:focus-visible {
      outline: 0;
    }
  
    &.increment {
      order: 1;
    }
  `);

  const NumberInput = React.forwardRef(function CustomNumberInput(props, ref) {
    const { value, onChange } = props;

    const handleIncrement = () => {
      console.log('Increment button clicked');
      const incrementedValue = (typeof value === 'number' ? value : parseFloat(value)) + 1;
      onChange(incrementedValue);
    };

    return (
      <BaseNumberInput
        slots={{
          root: StyledInputRoot,
          input: StyledInput,
          incrementButton: StyledButton,
          decrementButton: StyledButton,
        }}
        slotProps={{
          incrementButton: {
            children: <AddIcon fontSize="small" />,
            className: 'increment',
            onClick: handleIncrement,
          },
          decrementButton: {
            children: <RemoveIcon fontSize="small" />,
          },
        }}
        {...props}
        ref={ref}
      />
    );
  });


  return (
    <div>
      {loading ? (
        // Show the backdrop while loading
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (

        <div className="owner-container">
          <div className="profile-item-container">

            <IconButton
              aria-label="close"
              onClick={handleCloseSponsorDialog}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers>

              {/* Buttons */}
              <Grid item xs={12}>
                  
                  <Button variant="contained" color="secondary" onClick={handleView}>
                    {showTable ? 'Hide' : 'View'} Sponsor Periods
                  </Button>
                </Grid>

                <br/>
              <h2>General Information</h2>
              <h5> {sponserPrice}$ per day</h5>
              

              <Grid container spacing={2} alignItems="center" justifyContent="center">

                {/* Date Range Picker */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Grid container spacing={2} alignItems="center" justifyContent="center">

                            {/* Start Date Picker */}
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <DatePicker
                                    label="Start Date"
                                    value={startDate}
                                    onChange={(newValue) => {
                                    setStartDate(newValue);
                                    if (handleStartDateChange) handleStartDateChange(newValue);
                                    }}
                                    minDate={dayjs()} // Disable past dates
                                    renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                                />
                            </Grid>

                            {/* End Date Picker */}
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <DatePicker
                                    label="End Date"
                                    value={endDate}
                                    onChange={(newValue) => {
                                    setEndDate(newValue);
                                    if (handleEndDateChange) handleEndDateChange(newValue);
                                    }}
                                    minDate={startDate || dayjs()} // Disable dates before start date
                                    renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                                />
                            </Grid>

                        </Grid>
                    </LocalizationProvider>

                </Grid>
              
            
               

                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                        type="tel"
                        label="Price"
                        variant="outlined"
                        fullWidth
                        value={propertyData.price}
                        disabled 
                        // onChange={(e) => {
                        // const inputValue = e.target.value.slice(0, 4);
                        // const numericValue = parseInt(inputValue, 10); // Convert to a number

                        // if (!isNaN(numericValue) && numericValue >= 0) {
                        //     // Calculate the new price with a 3% increase
                        //     const newPrice = (numericValue * 1.03).toFixed(2); // Round to 2 decimal places
                        //     setPropertyData({
                        //     ...propertyData,
                        //     price: newPrice.toString(),
                        //     });
                        // } else {
                        //     setPropertyData({
                        //     ...propertyData,
                        //     price: '', // or some other default value if needed
                        //     });
                        // }
                        // }}
                        required
                        inputMode="numeric"
                        placeholder={propertyData.rent_buy === 0 ? "Max Length: 4 char" : "Enter price"}
                    />
                </Grid>

                

              </Grid>

              {/* Sponsor Periods Table */}
              {showTable && (
                <TableContainer component={Paper} sx={{ marginTop: 3 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>From Date</TableCell>
                        <TableCell>To Date</TableCell>
                        <TableCell>Price</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sponsorPeriods.map((period, index) => (
                        <TableRow key={index}>
                          <TableCell>{period.from_date}</TableCell>
                          <TableCell>{period.to_date}</TableCell>
                          <TableCell>{period.price}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            

            </DialogContent>

          </div>
        </div>
      )}
    </div>
  );
};

export default SponserProperty;

