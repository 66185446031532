import React, { useState, useEffect } from "react";
import Navbar from "../../../NavBar/NavBar";
import { TextField, Button, Grid, Typography } from "@mui/material";
import SnackbarComponent from "../../../SnackBar/SnackBar";
import axios from 'axios';
import Config from "../../../Services/Config";
import './BecomeOwner.css';
import BecomeOwnerPopup from "./BecomeOwnerPopup";
import { useNavigate } from "react-router-dom";

const BecomeOwner = () => {
  const [file, setFile] = useState(null);
  const [reason, setReason] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [profileData, setProfileData] = useState(null);
  const [ownerRequest, setOwnerRequest] = useState(0);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");

  const navigate = useNavigate();


 


  const [notificationBecomeOwner, setNotificationOwner] = useState(false);

  useEffect(() => {
    const fetchProfileData = async () => {
      try {

        const result = await Config.getUserDetails();
        
        if (result.success) {
          setProfileData(result.data);
          
          
          setOwnerRequest(result.request_response[0]["status"]);
          // console.log("result user details : ",ownerRequest);
        } else {
          console.log(result.message);
        }
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };

    fetchProfileData();
  }, []);

  useEffect(()=>{
      console.log("Owner Request : ",ownerRequest);
  },[ownerRequest]);


  const handleNotificationOwner = () => {
    navigate("/");
    window.location.reload();
  };


  const handleCloseNotificationOwner = () => {
    setNotificationOwner(true);
  }

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    if (selectedFile) {
      setImagePreview(URL.createObjectURL(selectedFile));
    }
  };

  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };

  const clearForm = () => {
    setFile(null);
    setReason("");
    setImagePreview(null);
    document.getElementById('file-upload').value = "";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const formData = new FormData();
    formData.append("file", file);
    formData.append("reason", reason);
  
    // Retrieve the token from localStorage
    // const token = localStorage.getItem("token"); // Replace 'your_token_key_here' with the actual key you used to store the token

    try {
      const response = await Config.requestOwnership(formData);


      if (response.success) {
        setOpenSnackbar(true);
        setSnackbarMessage(response.message);
        setSnackbarSeverity("success");

        handleCloseNotificationOwner();



      } else {
        setOpenSnackbar(true);
        setSnackbarMessage(response.message);
        setSnackbarSeverity("error");
      }
    } catch (error) {
      console.error("Error during the API call:", error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);

        console.log("error response",error.response);
      }
    }
  };
  


  return (
    <div>
      <Navbar ></Navbar>
      <div style={{ marginTop: '2%' }} className="myContainer">
        {
          profileData && profileData.type === 2  && (
            <div className="myContainer-item" style={{ height: '50px', backgroundColor: '#bb0129', opacity: 0.9, justifyContent: 'center', alignItems: 'center', margin: 0 }}>
               {ownerRequest === 0 ? 
                <h4 style={{ color: 'white' }}>Waiting Approval</h4>
                : ownerRequest === 2 ? <h4 style={{ color: 'white' }}>Request Ownership Refused</h4> : ""}
            </div>
          )


          

        }

        <div className="myContainer-item" style={{ marginTop: 0 }}>
          <h2 style={{ marginLeft: '2.5%' }}>Request to be an Owner</h2>
          <form onSubmit={handleSubmit} style={{ marginTop: '5%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Grid container style={{ justifyContent: 'center' }}>

              <Grid item xs={12} sm={10} lg={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '1%' }}>
                <div style={{ border: "1px solid #ddd", padding: "10px", textAlign: "center", width: '75%' }}>
                  {imagePreview ? (
                    <img src={imagePreview} alt="Preview" style={{ width: "100%", height: "100%" }} />
                  ) : (
                    <Typography variant="body1">No image uploaded</Typography>
                  )}
                </div>

                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: "1rem", gap: "10px" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    component="label"
                    style={{ marginLeft: "10px" }}
                  >
                    Upload File
                    <input
                      id="file-upload"
                      type="file"
                      hidden
                      onChange={handleFileChange}
                      accept=".pdf, .jpg, .jpeg, .png"
                    />
                  </Button>
                  <Button
                    variant="text"
                    onClick={clearForm}
                    style={{ marginLeft: "10px", color: 'grey' }}
                  >
                    Clear
                  </Button>
                </div>
              </Grid>

              <Grid item xs={12} sm={10} lg={6} style={{ display: 'flex', justifyContent: 'center', marginTop: '1%' }}>
                <TextField
                  style={{ width: '80%', marginBottom: "3%" }}
                  type="text"
                  label="Reason for Request"
                  value={reason}
                  onChange={handleReasonChange}
                  margin="normal"
                  variant="outlined"
                  multiline
                  rows={4}
                />
              </Grid>


              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{ marginTop: "40px", marginBottom: '5%', float: 'right', marginRight: '3.5%' }}
                >
                  Submit Request
                </Button>

              </Grid>
            </Grid>
          </form>
        </div>
      </div>

      <SnackbarComponent
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />

    <BecomeOwnerPopup isOpen={notificationBecomeOwner} onClose={handleNotificationOwner}/>


    </div>


    

  );
};

export default BecomeOwner;
