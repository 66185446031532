import React, { useState } from 'react';
import './RoomButtons.css';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';

function RoomButtons({ selectedBedroomButton, selectedBedsButton, selectedBathroomButton, onRoomChange}) {

  // console.log("selectedBedroomButton",selectedBedroomButton);
  // console.log("selectedBedsButton",selectedBedsButton);
  // console.log("selectedBathroomButton",selectedBathroomButton);


  const handleButtonClick = (buttonKey, buttonType) => {
      onRoomChange(buttonType, buttonKey);
  };

  const renderButton = (buttonKey, label, buttonType, selectedButton) => (
    <Button
      key={buttonKey}
      className={`tttt ${selectedButton === buttonKey ? 'blackButton' : ''}`}
      // style={{ backgroundColor: `${selectedButton === buttonKey ? 'yellow' : ''}` }}
      onClick={() => handleButtonClick(buttonKey, buttonType)}
      size="large"
    >
      {label}
    </Button>
  );
  

  return (
    <div className="rooms-filter-buttons">
      <div className="Bedroom-btns">
        <h4 className="r-h3">Bedroom(s)</h4>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', '& > *': { m: 1 }, fontFamily: 'MMC Regular',  }}>
          <ButtonGroup size="large" aria-label="large button group" sx={{ fontFamily: 'MMC Regular' }}  >
            {renderButton('0', 'Any', 'bedroom', selectedBedroomButton)}
            {renderButton('1', '1', 'bedroom', selectedBedroomButton)}
            {renderButton('2', '2', 'bedroom', selectedBedroomButton)}
            {renderButton('3', '3', 'bedroom', selectedBedroomButton)}
            {renderButton('4', '4+', 'bedroom', selectedBedroomButton)}
          </ButtonGroup>
        </Box>
      </div>

      <div className="Bedroom-btns">
        <h4 className="r-h3">Bed(s)</h4>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', '& > *': { m: 1 } }}>
          <ButtonGroup size="large" aria-label="large button group">
            {renderButton('0', 'Any', 'beds', selectedBedsButton)}
            {renderButton('1', '1', 'beds', selectedBedsButton)}
            {renderButton('2', '2', 'beds', selectedBedsButton)}
            {renderButton('3', '3', 'beds', selectedBedsButton)}
            {renderButton('4', '4+', 'beds', selectedBedsButton)}
          </ButtonGroup>
        </Box>
      </div>

      <div className="Bedroom-btns">
        <h4 className="r-h3">Bathroom(s)</h4>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', '& > *': { m: 1 } }}>
          <ButtonGroup size="large" aria-label="large button group">
            {renderButton('0', 'Any', 'bathroom', selectedBathroomButton)}
            {renderButton('1', '1', 'bathroom', selectedBathroomButton)}
            {renderButton('2', '2', 'bathroom', selectedBathroomButton)}
            {renderButton('3', '3', 'bathroom', selectedBathroomButton)}
            {renderButton('4', '4+', 'bathroom', selectedBathroomButton)}
          </ButtonGroup>
        </Box>
      </div>
    </div>
  );
}

export default RoomButtons;
