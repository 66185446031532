import React, { useState, useEffect  } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';

const MapComponentView = ({ latitude, longitude  }) => {
 

  // const [mapCenter, setMapCenter] = useState({ lat: latitude, lng: longitude });
  const [mapCenter, setMapCenter] = useState({ lat: 33.8938, lng: 35.5018 });
  const [markerPosition, setMarkerPosition] = useState(null);
  // const handleMapClick = (event) => {
  //   const { latLng } = event;
  //   const lat = latLng.lat();
  //   const lng = latLng.lng();

  //   setMarkerPosition({ lat, lng });

  //   onLocationChange({ latitude: lat, longitude: lng });
  // };
  useEffect(() => {
    
    const lat = Number(latitude);
    const lng = Number(longitude);
    setMapCenter({ lat: lat, lng: lng });
    setMarkerPosition({ lat: lat, lng: lng });
  }, [latitude, longitude]);

  return (
    <GoogleMap
      mapContainerStyle={{ height: '400px', width: '100%' }}
      zoom={14}
      center={mapCenter}
      
      
    >
      {markerPosition && <Marker position={markerPosition} />}
    </GoogleMap>
  );
 
};

export default MapComponentView;
