import React from 'react';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Login from "../LoginSignup/Login";
import { Button } from 'antd';

const QuestionLoginPopup = ({ isOpen, onClose, onClickLogIn }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
    bgcolor: "background.paper",
    // border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    borderRadius: "10px",
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>

      <div className="modal-content">
        <div className="modal-header">
          {/* <button className="close-button" onClick={onClose}>
            x
          </button> */}
        </div>
        <div className="modal-body">
          <label>You have to login first, do you want to continue to logging in?</label>
          <br/><br/><br/>
          <div className="button-container">
            <Button style={{marginRight:'10px',backgroundColor:'brown',color:'white',border:'0px'}} onClick={onClickLogIn}>Yes</Button>
            <Button style={{backgroundColor:'brown',color:'white',border:'0px'}} onClick={onClose}>No</Button>
          </div>
        </div>
      </div>


      </Box>
    </Modal>
  );
};

export default QuestionLoginPopup;
